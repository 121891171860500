import {
  Button,
  Col,
  ConfigProvider,
  InputNumber,
  Modal,
  Row,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { CloseOutlined } from "@ant-design/icons";
import Text from "../components/Text";
import icon1 from "../assets/Svg/usdcCoin.svg";
import icon2 from "../assets/Svg/Diamond.svg";
import upcirle from "../assets/Svg/FiArrowUpCircle.svg";
import RemoveLiquidityModal from "./RemoveLiquidityModal";
import Web3 from "web3";
import { erc20ABI, useAccount } from "wagmi";
import fiIconCheck from "../assets/FiCheckCircle.svg";
import BigNumber from "bignumber.js";
import rangeicon from "../assets/arrow_range.svg";

import {
  getPositionManagerAllowance,
  getPositionManagerApproval,
  getPrice,
  getTokenBalance,
  collectFees,
  addLiquidity,
} from "../utils/poolFunctions";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";

import { FACTORY_ADDRESS, POSITION_MANAGER_ADDRESS } from "../constants";
import { computePoolAddress } from "@uniswap/v3-sdk";
import { ChainId, Token } from "@shidoglobal/sdk-core";
import { formatLiquidity, formatNumberUniversal } from "../utils";
import { fetchTokenPrices } from "../api/ApiCalls";
import { useError } from "../contexts/ErrorContext";
import positionManagerABI from "../abis/positionManager.json";
import { toReadableAmount } from "../utils/tradeUtils";
import { UpOutlined } from "@ant-design/icons";
interface ModalProps {
  open: boolean;
  activePosition: any;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  positionIds: any;
  refreshPosition: any;
}
const YourLiquidityModal = (prop: ModalProps) => {
  const { open, setState, activePosition, positionIds, refreshPosition } = prop;
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stepper, setStepper] = useState<number>(1);
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [claimed, setClaimed] = useState<boolean>(false);

  const handleOk = () => {
    setState(false);
    setStepper(1);
  };
  const handleCancel = () => {
    if (claimed === true) {
      setClaimed(false);
    }
    setState(false);
    setStepper(1);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: { contentBg: " #252527" },
          Button: {
            borderColorDisabled: "#A3D3FB",
            colorBgContainerDisabled: "#A3D3FB",
          },
          Select: {
            colorBgContainer: "#45454A",
            colorBorder: "#45454A",
            borderRadiusSM: 50,
            colorTextPlaceholder: "white",
          },
        },
      }}
    >
      <Modal
        width={lg ? 550 : xs ? 367 : 445}
        styles={{ content: { padding: xs ? "12px" : "24px" } }}
        footer={false}
        closable={false}
        centered
        open={open}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <CloseOutlined
          style={{
            color: "white",
            position: "absolute",
            right: 24,
          }}
          onClick={handleCancel}
        />
        {stepper === 1 && (
          <Stepper1
            stepper={stepper}
            setStepper={setStepper}
            CloseModal={handleCancel}
            position={activePosition}
            setNextModal={setOpenRemoveModal}
            positionIds={positionIds}
            loading={loading}
            setLoading={setLoading}
            claimed={claimed}
            setClaimed={setClaimed}
            refreshPosition={refreshPosition}
          />
        )}
        {stepper === 2 && (
          <Stepper2
            stepper={stepper}
            setStepper={setStepper}
            CloseModal={handleCancel}
            setNextModal={setOpenRemoveModal}
            position={activePosition}
            positionIds={positionIds}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {stepper === 3 && (
          <Stepper3 stepper={stepper} setStepper={setStepper} />
        )}
        {stepper == 4 && (
          <Stepper4
            stepper={stepper}
            setStepper={setStepper}
            CloseModal={handleOk}
          />
        )}
      </Modal>
      <RemoveLiquidityModal
        setState={setOpenRemoveModal}
        open={openRemoveModal}
        activePosition={activePosition}
        positionIds={positionIds}
        loading={loading}
        setLoading={setLoading}
        setLiquidityModel={setState}
        refreshPosition={refreshPosition}
      />
    </ConfigProvider>
  );
};

export default YourLiquidityModal;

interface Stepper1Prop {
  stepper: number;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  CloseModal: () => void;
  setNextModal: React.Dispatch<React.SetStateAction<boolean>>;
  position: any;
  positionIds: any;
  loading: boolean;
  setLoading: any;
  claimed: boolean;
  setClaimed: React.Dispatch<React.SetStateAction<boolean>>;
  refreshPosition: any;
}

const Stepper1 = (props: Stepper1Prop) => {
  const {
    setStepper,
    stepper,
    CloseModal,
    position,
    setNextModal,
    positionIds,
    loading,
    setLoading,
    claimed,
    setClaimed,
    refreshPosition,
  } = props;

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { connector, address } = useAccount();
  const { setError } = useError();
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");

  const onCollectFees = async () => {
    setLoading(true);

    if (
      Number(position.tokensOwed0.toFixed()) === 0 &&
      Number(position.tokensOwed1.toFixed()) === 0
    ) {
      setError({
        message: "No fees available for collection",
        type: "error",
      });

      setLoading(false);
      return;
    }

    try {
      const web3 = new Web3(await connector?.getProvider());

      const positionId = positionIds[position.positionIdIndex];

      await collectFees(positionId, address, web3, position);

      await refreshPosition(positionId);

      setError({
        message: "Fees collected successfully",
        type: "success",
      });

      setClaimed(true);
    } catch (e) {
      setError({
        message: "Error collecting fees",
        type: "success",
      });
    }
    setLoading(false);
  };

  const handleRemoveButton = () => {
    CloseModal();
    setNextModal(true);
  };

  const tickToPrice = (
    tick: number,
    token0Decimals: number,
    token1Decimals: number
  ) => {
    const decimalDifference = token0Decimals - token1Decimals;
    const sqrtRatioX96 = Math.pow(1.0001, tick / 2);
    const price = sqrtRatioX96 ** 2 * 10 ** decimalDifference;
    return new BigNumber(price);
  };

  useEffect(() => {
    const calc = async () => {
      const lowPrice = tickToPrice(
        Number(position.tickLower),
        Number(position.token0Decimals),
        Number(position.token1Decimals)
      );

      const highPrice = tickToPrice(
        Number(position.tickUpper),
        Number(position.token0Decimals),
        Number(position.token1Decimals)
      );

      const currentPrice = tickToPrice(
        Number(position.poolTick),
        Number(position.token0Decimals),
        Number(position.token1Decimals)
      );
      setMinPrice(formatNumberUniversal(lowPrice.toFixed()));
      setMaxPrice(formatNumberUniversal(highPrice.toFixed()));
      setCurrentPrice(formatNumberUniversal(currentPrice.toFixed()));
    };

    if (position) {
      calc();
    }
  }, [position]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            borderRadiusSM: 8,
            paddingInlineSM: 12,
          },
        },
      }}
    >
      <Row style={{ gap: "16px" }}>
        <Row>
          <Text size={xs ? "md" : "lg"} style={{ fontWeight: 600 }}>
            Your Liquidity
          </Text>
        </Row>
        {!claimed ? (
          <Row
            style={{
              flexDirection: "column",
              borderRadius: "12px",
              backgroundColor: "#37373C",
              width: "100%",
              padding: "16px",
            }}
          >
            <Row
              style={{
                alignItems: "center",
                width: "100%",
                gap: "10px",
                paddingBottom: "16px",
                borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
              }}
            >
              <Col
                style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
              >
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={position.token0image}
                    style={{
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <img
                    src={position.token1image}
                    style={{
                      height: "24px",
                      width: "24px",
                      position: "relative",
                      left: "-6px",
                      top: "1px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                </Col>

                <Text size="sm" style={{ fontWeight: "600" }}>
                  {position.token0Symbol}/ {position.token1Symbol}
                </Text>
              </Col>

              <Button
                type="primary"
                size="small"
                style={{ justifySelf: "end" }}
              >
                Manage
                <UpOutlined />
              </Button>
            </Row>
            <Row
              style={{
                marginTop: "16px",
                alignItems: "center",
                gap: "7px",
                fontSize: "500",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor:
                    position.isInRange === true ? "green" : "yellow",
                }}
              ></div>
              {position.isInRange === true ? (
                <Text size="sm" style={{ color: "green" }}>
                  Price is in of range
                </Text>
              ) : (
                <Text size="sm" style={{ color: "yellow" }}>
                  Price is out of range
                </Text>
              )}
            </Row>

            <Row
              style={{
                alignItems: "center",
                gap: "12px",
                marginTop: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#252527",
                  borderRadius: "12px",
                  padding: "18px 16px",
                  flex: 1,
                  gap: 4,
                }}
              >
                <Text size="xs" style={{ fontWeight: "500", color: "#7C7C82" }}>
                  Minimum
                </Text>
                <Text
                  size="xs"
                  style={{ fontWeight: "600", color: "#FFF", fontSize: "18px" }}
                >
                  {minPrice}
                </Text>
                <Text size="xs" style={{ fontWeight: "500", color: "#7C7C82" }}>
                  {position.token1Symbol} per {position.token0Symbol}
                </Text>
              </div>
              <img src={rangeicon} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#252527",
                  borderRadius: "12px",
                  padding: "18px 16px",
                  flex: 1,
                  gap: 4,
                }}
              >
                <Text size="xs" style={{ fontWeight: "500", color: "#7C7C82" }}>
                  Maximum
                </Text>
                <Text
                  size="xs"
                  style={{ fontWeight: "600", color: "#FFF", fontSize: "18px" }}
                >
                  {String(maxPrice) === "3.3849213185191664e+26"
                    ? "∞"
                    : maxPrice}
                </Text>
                <Text size="xs" style={{ fontWeight: "500", color: "#7C7C82" }}>
                  {position.token1Symbol} per {position.token0Symbol}
                </Text>
              </div>
            </Row>

            <Row
              style={{
                marginTop: "10px",
                backgroundColor: "#252527",
                padding: "18px 16px",
                borderRadius: "12px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text size="xs" style={{ fontWeight: "500", color: "#7C7C82" }}>
                Current Price ({position.token1Symbol} per{" "}
                {position.token0Symbol})
              </Text>
              <Text
                size="xs"
                style={{ fontWeight: "600", color: "#FFF", fontSize: "18px" }}
              >
                {currentPrice}
              </Text>
            </Row>

            <Row
              style={{
                width: "100%",
                flexDirection: "column",
                gap: "10px",
                padding: "16px 0px",

                borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
              }}
            >
              {position.isInRange === true && (
                <DataRow
                  name="Your Pool share"
                  value={
                    String(
                      formatNumberUniversal(position.liquidityPercentage)
                    ) + "%"
                  }
                />
              )}
              <DataRow
                name={"Pooled " + position.token0Symbol}
                value={formatNumberUniversal(position.pooledToken0.toString())}
                icon={position.token0image}
              />
              <DataRow
                name={"Pooled " + String(position.token1Symbol)}
                value={formatNumberUniversal(position.pooledToken1.toString())}
                icon={position.token1image}
              />

              <DataRow
                name={`Unclaimed ${position.token0Symbol}`}
                value={formatNumberUniversal(
                  toReadableAmount(
                    position.tokensOwed0.toFixed(),
                    position.token0Decimals
                  )
                )}
              />

              <DataRow
                name={`Unclaimed ${position.token1Symbol}`}
                value={formatNumberUniversal(
                  toReadableAmount(
                    position.tokensOwed1.toFixed(),
                    position.token1Decimals
                  )
                )}
              />

              <DataRow
                name="current fee"
                value={String(Number(position.fee) / 10000) + "%"}
                namecolor="#3B87F7"
                style={{ marginTop: "10px" }}
              />
            </Row>

            <Row gutter={16} style={{ marginTop: "16px" }}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", opacity: loading ? "0.5" : "1" }}
                  type="primary"
                  disabled={!position.isInRange === true}
                  onClick={() => {
                    if (!loading) {
                      setStepper(stepper + 1);
                    }
                  }}
                >
                  Add liquidity
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  style={{ width: "100%", opacity: loading ? "0.5" : "1" }}
                  type="primary"
                  onClick={() => {
                    if (!loading) {
                      handleRemoveButton();
                    }
                  }}
                >
                  Remove{" "}
                </Button>
              </Col>
            </Row>
            <Button
              style={{ width: "100%", marginTop: "16px" }}
              type="primary"
              onClick={onCollectFees}
              loading={loading}
              disabled={
                Number(position.tokensOwed0.toFixed()) === 0 &&
                Number(position.tokensOwed1.toFixed()) === 0
              }
            >
              Claim Fees
            </Button>

            {loading && (
              <Text
                size="xs"
                style={{ width: "100%", textAlign: "center", marginTop: "8px" }}
              >
                Processing, this may take some time...
              </Text>
            )}
          </Row>
        ) : (
          <Row
            style={{
              width: "100%",
              borderRadius: "12px",
              backgroundColor: " #37373C",
              gap: "8px",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px 0px",
            }}
          >
            <img src={fiIconCheck} />
            <Text size="md" style={{ width: "100%", textAlign: "center" }}>
              Fees claimed succesfully!
            </Text>
          </Row>
        )}
      </Row>
    </ConfigProvider>
  );
};

interface Stepper2Prop {
  stepper: number;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  CloseModal: () => void;
  setNextModal: React.Dispatch<React.SetStateAction<boolean>>;
  position: any;
  positionIds: any;
  loading: boolean;
  setLoading: any;
}
const Stepper2 = (props: Stepper2Prop) => {
  const {
    setStepper,
    stepper,
    CloseModal,
    setNextModal,
    position,
    positionIds,
    loading,
    setLoading,
  } = props;

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [exchangeRate, setExchangeRate] = useState<any>({
    token0: 0,
    token1: 0,
  });
  const { setError } = useError();

  const [token0Balance, setToken0Balance] = useState<number>(0);
  const [token1Balance, setToken1Balance] = useState<number>(0);
  const [token0DepositAmount, setToken0DepositAmount] = useState<number>(0);
  const [token1DepositAmount, setToken1DepositAmount] = useState<number>(0);
  const [token0Allowance, setToken0Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const [token1Allowance, setToken1Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const [token0DollarValue, setToken0DollarValue] = useState<number>(0);
  const [token1DollarValue, setToken1DollarValue] = useState<number>(0);

  useEffect(() => {
    const getPrices = async () => {
      try {
        const addresses = [
          position.token0.toLowerCase(),
          position.token1.toLowerCase(),
        ];
        const prices = await fetchTokenPrices(addresses);

        if (prices.prices) {
          setToken0DollarValue(
            prices.prices[position.token0.toLowerCase()].toLowerCase()
          );
          setToken1DollarValue(
            prices.prices[position.token1.toLowerCase()].toLowerCase()
          );
        }
      } catch (e: any) {
        setError({
          message: e.message,
          type: "error",
        });
      }
    };
    if (position && position.token0 && position.token1) {
      getPrices();
    }
  }, [position]);

  const { connector, address } = useAccount();

  const getTokenBalances = async () => {
    if (position.token0) {
      const balance = await getTokenBalance(address, position.token0);
      setToken0Balance(Number(balance));
    } else {
      setToken0Balance(0);
    }

    if (position.token1) {
      const balance = await getTokenBalance(address, position.token1);
      setToken1Balance(Number(balance));
    } else {
      setToken1Balance(0);
    }
  };

  const loadPoolData = async () => {
    const prices = await getPrice({
      sqrtPriceX96: position.poolSqrtPrice,
      Decimal0: Number(position.token1Decimals),
      Decimal1: Number(position.token0Decimals),
    });

    setExchangeRate(prices);
  };

  useEffect(() => {
    if (position && connector?.getProvider()) {
      loadPoolData();
      setLoading(false);
    }
  }, [position, connector]);

  useEffect(() => {
    if (position && address) {
      getTokenBalances();
    }
  }, [position, address]);

  const handleMaxToken0 = () => {
    setToken0DepositAmount(token0Balance);

    const calculatedToken1 = token0Balance * exchangeRate.token0;
    setToken1DepositAmount(
      Number(formatNumberUniversal(String(calculatedToken1)))
    );
  };

  const handleMaxToken1 = () => {
    setToken1DepositAmount(token1Balance);

    const calculatedToken0 = token1Balance * exchangeRate.token0;
    setToken0DepositAmount(
      Number(formatNumberUniversal(String(calculatedToken0)))
    );
  };

  const handleInputChange = (value: any, index: any) => {
    if (index === 0) {
      setToken0DepositAmount(value);
      if (value) {
        const calculatedToken1 = value * exchangeRate.token0;
        setToken1DepositAmount(
          Number(formatNumberUniversal(String(calculatedToken1)))
        );
      } else {
        setToken1DepositAmount(0);
      }
    } else if (index === 1) {
      setToken1DepositAmount(value);
      if (value) {
        const calculatedToken0 = value * exchangeRate.token1;

        setToken0DepositAmount(
          Number(formatNumberUniversal(String(calculatedToken0)))
        );
      } else {
        setToken0DepositAmount(0);
      }
    }
  };

  const getToken0Allowance = async () => {
    if (position.token0 && address) {
      const allowance = await getPositionManagerAllowance(
        address,
        position.token0
      );

      setToken0Allowance(allowance);
    }
  };

  const getToken1Allowance = async () => {
    if (position.token1 && address) {
      const allowance = await getPositionManagerAllowance(
        address,
        position.token1
      );

      setToken1Allowance(allowance);
    }
  };

  useEffect(() => {
    if (
      token0Allowance?.isEqualTo(BigNumber(0)) ||
      token0Allowance?.isGreaterThan(BigNumber(token0DepositAmount))
    ) {
      getToken0Allowance();
    }
  }, [position, token0DepositAmount, address]);

  useEffect(() => {
    if (
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isGreaterThan(BigNumber(token1DepositAmount))
    ) {
      getToken1Allowance();
    }
  }, [position, token1DepositAmount, address]);

  const allowAddPosition = () => {
    return (
      token0Allowance.isEqualTo(BigNumber(0)) ||
      token0Allowance.isLessThan(BigNumber(token0DepositAmount)) ||
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isLessThan(BigNumber(token1DepositAmount)) ||
      BigNumber(token0DepositAmount).isEqualTo(0) ||
      BigNumber(token1DepositAmount).isEqualTo(0) ||
      BigNumber(token0Balance).isLessThan(token0DepositAmount) ||
      BigNumber(token1Balance).isLessThan(token1DepositAmount)
    );
  };

  const addPosition = async () => {
    setLoading(true);
    try {
      const web3 = new Web3(await connector?.getProvider());
      const chain_id = Number(await web3.eth.getChainId());

      const tokenA = new Token(
        Number(chain_id),
        position.token0,
        Number(position.token1Decimals),
        position.token0Symbol,
        position.token0Symbol
      );

      const tokenB = new Token(
        Number(chain_id),
        position.token1,
        Number(position.token1Decimals),
        position.token1Symbol,
        position.token1Symbol
      );

      const poolAddress = computePoolAddress({
        factoryAddress: FACTORY_ADDRESS,
        tokenA: tokenA,
        tokenB: tokenB,
        fee: position.fee,
      });

      const poolContract = new web3.eth.Contract(
        UniswapV3Pool.abi,
        poolAddress
      );

      const positionId = positionIds[position.positionIdIndex];

      const response = await addLiquidity(
        position,
        address,
        web3,
        poolContract,
        Number(positionId),
        token0DepositAmount,
        token1DepositAmount
      );

      if (response.transactionHash) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        getTokenBalances();
        setError({
          message: "Liquidity added successfully",
          type: "success",
        });
        setToken0DepositAmount(0);
        setToken1DepositAmount(0);
      } else {
        setError({
          message: "Failed to add liquidity",
          type: "error",
        });
      }
      setLoading(false);
    } catch (error: any) {
      setError({
        message: "Failed to add liquidity",
        description: error.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorTextPlaceholder: "#9B9CA3",
            fontSize: 24,
            paddingInline: 2,
          },
        },
      }}
    >
      <Row>
        <Row
          style={{
            justifyContent: "center",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Text
            size={xs ? "md" : "lg"}
            style={{
              fontWeight: 600,
              textAlign: "center",
              fontFamily: "Inter",
            }}
          >
            Your Liquidity
          </Text>
        </Row>
        <Row
          style={{
            flexDirection: "column",
            backgroundColor: "#37373C",
            borderRadius: "12px",
            gap: "16px",
            width: "100%",
            padding: "16px",
          }}
        >
          <Row>
            <Text size="lg">Select Pair</Text>
          </Row>
          <Row
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
              gap: "10px",
            }}
          >
            <Col style={{ flexGrow: "1" }}>
              <Row
                style={{
                  backgroundColor: "#45454A",
                  borderRadius: "25px",
                  padding: "5px 12px 5px 5px",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img
                  src={position.token0image}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <h5
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {position.token0Symbol}
                </h5>
              </Row>
            </Col>
            <Col style={{ flexGrow: "1" }}>
              <Row
                style={{
                  backgroundColor: "#45454A",
                  borderRadius: "25px",
                  padding: "5px 12px 5px 5px",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img
                  src={position.token1image}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <h5
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  {position.token1Symbol}
                </h5>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              padding: "12px",
              borderRadius: "14px",
              backgroundColor: "#252527",
              gap: "12px",
            }}
          >
            <Text size="sm" style={{ fontWeight: "500" }}>
              Fee Tiers
            </Text>
            <Row style={{ width: "100%", gap: "12px" }}>
              <Col style={{ flex: "1" }}>
                <Tooltip title="You can't change the fee">
                  <Col
                    className={`AddLiquidityFeeTires`}
                    style={{
                      border:
                        Number(position.fee) === 3000
                          ? "2px solid #3b87f7"
                          : "",
                    }}
                  >
                    <Text
                      size="sm"
                      style={{
                        color:
                          Number(position.fee) === 5000 ? "White" : "#9B9CA3",
                        fontWeight: "600",
                      }}
                    >
                      0.3%
                    </Text>
                    <Text
                      size="xs"
                      style={{
                        color:
                          Number(position.fee) === 10000 ? "White" : "#9B9CA3",
                        fontWeight: "400",
                      }}
                    >
                      Low
                    </Text>
                  </Col>
                </Tooltip>
              </Col>
              <Col style={{ flex: "1" }}>
                <Tooltip title="You can't change the fee">
                  <Col
                    className={`AddLiquidityFeeTires`}
                    style={{
                      border:
                        Number(position.fee) === 500 ? "2px solid #3b87f7" : "",
                    }}
                    onClick={() => {
                      // handlefee("medium");
                    }}
                  >
                    <Text
                      size="sm"
                      style={{
                        color:
                          Number(position.fee) === 500 ? "White" : "#9B9CA3",
                        fontWeight: "600",
                      }}
                    >
                      5%
                    </Text>
                    <Text
                      size="xs"
                      style={{
                        color:
                          Number(position.fee) === 500 ? "White" : "#9B9CA3",
                        fontWeight: "400",
                      }}
                    >
                      Medium
                    </Text>
                  </Col>
                </Tooltip>
              </Col>{" "}
              <Col style={{ flex: "1" }}>
                <Tooltip title="You can't change the fee">
                  <Col
                    className={`AddLiquidityFeeTires`}
                    style={{
                      border:
                        Number(position.fee) === 10000
                          ? "2px solid #3b87f7"
                          : "",
                    }}
                    onClick={() => {
                      // handlefee("high");
                    }}
                  >
                    <Text
                      size="sm"
                      style={{
                        color:
                          Number(position.fee) === 10000 ? "White" : "#9B9CA3",
                        fontWeight: "600",
                      }}
                    >
                      10%
                    </Text>
                    <Text
                      size="xs"
                      style={{
                        color:
                          Number(position.fee) === 10000 ? "White" : "#9B9CA3",
                        fontWeight: "400",
                      }}
                    >
                      High
                    </Text>
                  </Col>
                </Tooltip>
              </Col>
            </Row>
          </Row>
          <Row
            style={{
              padding: "12px",
              borderRadius: "14px",
              backgroundColor: "#252527",
              gap: "12px",
            }}
          >
            <Text size="sm" style={{ fontWeight: "500" }}>
              Deposit Amounts
            </Text>
            <Row style={{ width: "100%", gap: "12px" }}>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#37373C",
                  borderRadius: "12px",
                  padding: "16px",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputNumber
                    type="number"
                    onChange={(value) => {
                      handleInputChange(value, 0);
                    }}
                    disabled={loading}
                    value={token0DepositAmount}
                    placeholder="0"
                    style={{
                      marginLeft: "-3px",
                      width: lg ? "150px" : xs ? "70px" : "100px",
                    }}
                  />
                  <h5
                    style={{
                      color: "#9B9CA3",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    $
                    {Number(token0DepositAmount) && Number(token0DollarValue)
                      ? formatNumberUniversal(
                          String(
                            Number(token0DepositAmount) *
                              Number(token0DollarValue)
                          )
                        )
                      : "0.00"}
                  </h5>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Row
                    style={{
                      backgroundColor: "#45454A",
                      borderRadius: "25px",
                      padding: "5px 12px 5px 5px",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={position.token0image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <h5
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0",
                      }}
                    >
                      {position.token0Symbol}
                    </h5>
                  </Row>
                  <Row style={{ gap: "12px", alignItems: "center" }}>
                    <h5
                      style={{
                        margin: 0,
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Balance : {formatNumberUniversal(String(token0Balance))}
                    </h5>
                    <div
                      style={{
                        backgroundColor: "#3B87F7",
                        borderRadius: "6px",
                        padding: " 2px 7px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: loading ? "default" : "pointer",
                        opacity: loading ? 0.5 : 1,
                      }}
                      onClick={() => (loading ? () => {} : handleMaxToken0())}
                    >
                      Max
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#37373C",
                  borderRadius: "12px",
                  padding: "16px",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputNumber
                    type="number"
                    onChange={(value) => {
                      handleInputChange(value, 1);
                    }}
                    disabled={loading}
                    placeholder="0"
                    value={token1DepositAmount}
                    style={{
                      marginLeft: "-3px",
                      width: lg ? "150px" : xs ? "70px" : "100px",
                    }}
                  />
                  <h5
                    style={{
                      color: "#9B9CA3",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    $
                    {Number(token1DepositAmount) && Number(token1DollarValue)
                      ? formatNumberUniversal(
                          String(
                            Number(token1DepositAmount) *
                              Number(token1DollarValue)
                          )
                        )
                      : "0.00"}
                  </h5>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Row
                    style={{
                      backgroundColor: "#45454A",
                      borderRadius: "25px",
                      padding: "5px 12px 5px 5px",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={position.token1image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <h5
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0",
                      }}
                    >
                      {position.token1Symbol}
                    </h5>
                  </Row>
                  <Row style={{ gap: "12px", alignItems: "center" }}>
                    <h5
                      style={{
                        margin: 0,
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Balance : {formatNumberUniversal(String(token1Balance))}
                    </h5>
                    <div
                      style={{
                        backgroundColor: "#3B87F7",
                        borderRadius: "6px",
                        padding: " 2px 7px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: loading ? "default" : "pointer",
                        opacity: loading ? 0.5 : 1,
                      }}
                      onClick={() => (loading ? () => {} : handleMaxToken1())}
                    >
                      Max
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Row>
          <Row>
            {token0Allowance.isLessThan(BigNumber(token0DepositAmount)) && (
              <Button
                loading={loading}
                type="primary"
                style={{ width: "100%", marginTop: "36px" }}
                onClick={async () => {
                  setLoading(true);

                  const maxApprovalValue = new BigNumber(
                    "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
                  );
                  const scaledValue = maxApprovalValue
                    .multipliedBy(new BigNumber(10).pow(18))
                    .integerValue();

                  const approvalResult = await getPositionManagerApproval(
                    address,
                    position.token0,
                    scaledValue
                  );

                  if (approvalResult && approvalResult.transactionHash) {
                    setToken0Allowance(BigNumber(scaledValue));
                  }
                  setLoading(false);
                }}
              >
                Approve {position.token0Symbol}
              </Button>
            )}

            {token1Allowance.isLessThan(BigNumber(token1DepositAmount)) && (
              <Button
                loading={loading}
                type="primary"
                style={{ width: "100%", marginTop: "10px" }}
                onClick={async () => {
                  try {
                    setLoading(true);

                    const maxApprovalValue = new BigNumber(
                      "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
                    );
                    const scaledValue = maxApprovalValue
                      .multipliedBy(new BigNumber(10).pow(18))
                      .integerValue();

                    const approvalResult = await getPositionManagerApproval(
                      address,
                      position.token1,
                      scaledValue
                    );

                    if (approvalResult && approvalResult.transactionHash) {
                      setToken1Allowance(BigNumber(scaledValue));
                    }
                    setLoading(false);
                  } catch (error) {
                    setLoading(false);
                  }
                }}
              >
                Approve {position.token1Symbol}
              </Button>
            )}
          </Row>
          <Row style={{ width: "100%" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                addPosition();
              }}
              loading={loading}
              disabled={allowAddPosition()}
            >
              Add liquidity{" "}
            </Button>
          </Row>
        </Row>
      </Row>
    </ConfigProvider>
  );
};

interface Stepper3Prop {
  stepper: number;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
}
const Stepper3 = (props: Stepper3Prop) => {
  const { setStepper, stepper } = props;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row>
      <Row
        style={{
          justifyContent: "center",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <Text
          size={xs ? "md" : "lg"}
          style={{ fontWeight: 600, textAlign: "center", fontFamily: "Inter" }}
        >
          Your Liquidity
        </Text>
      </Row>
      <Row
        style={{
          flexDirection: "column",
          padding: "16px",
          borderRadius: "12px",
          backgroundColor: "#37373C",
          width: "100%",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            width: "100%",
            gap: "10px",
            marginBottom: "16px",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center" }}>
            <img
              src={icon1}
              style={{
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
            <img
              src={icon2}
              style={{
                height: "24px",
                width: "24px",
                position: "relative",
                left: "-6px",
                top: "1px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          </Col>

          <Text size="sm" style={{ fontWeight: "600" }}>
            USDC/BTC
          </Text>
        </Row>
        <Row
          style={{
            padding: "24px 0px",
            gap: "8px",
            borderTop: "1px solid rgba(255, 255, 255, 0.10)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
          }}
        >
          <AddDataRow icon={icon1} name="USDC" value="0.1902" />
          <AddDataRow icon={icon2} name="BTC" value="0.19902" />
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text size="sm">Free Tire</Text>
            <Text size="sm">0.3%</Text>
          </Row>
        </Row>
        <Button
          type="primary"
          style={{ marginTop: "24px" }}
          onClick={() => {
            setStepper(stepper + 1);
          }}
        >
          Add
        </Button>
      </Row>
    </Row>
  );
};

interface Stepper4Prop {
  stepper: number;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  CloseModal: () => void;
}
const Stepper4 = (props: Stepper4Prop) => {
  const { setStepper, stepper, CloseModal } = props;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row style={{ flexDirection: "column", gap: "36px" }}>
      <Row
        style={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Text
          size={xs ? "md" : "lg"}
          style={{ fontWeight: 600, textAlign: "center", fontFamily: "Inter" }}
        >
          Transaction Complete
        </Text>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <img src={upcirle} />
      </Row>
      <Button
        type="primary"
        onClick={() => {
          CloseModal();
        }}
      >
        Close
      </Button>
      <Text
        size="sm"
        style={{
          color: "#3B87F7",
          fontWeight: "400",
          width: "100%",
          textAlign: "center",
          marginTop: "-20px",
        }}
      >
        View on BlockChain
      </Text>
    </Row>
  );
};
interface DataRowProp {
  name: string;
  value: string;
  icon?: string;
  namecolor?: string;
  style?: React.CSSProperties;
}
const DataRow = (props: DataRowProp) => {
  const { name, value, icon, namecolor, style } = props;
  return (
    <Row
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <Text size="sm" style={{ color: namecolor ? namecolor : "#9B9CA3" }}>
        {name}
      </Text>
      <Row style={{ alignItems: "center", gap: "8px" }}>
        {icon && (
          <img
            src={icon}
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        )}
        <Text size="sm">{value}</Text>
      </Row>
    </Row>
  );
};

interface AddDataRowProp {
  name: string;
  value: string;
  icon?: string;
  namecolor?: string;
  style?: React.CSSProperties;
}

const AddDataRow = (props: AddDataRowProp) => {
  const { name, value, icon, namecolor, style } = props;
  return (
    <Row
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <Row style={{ alignItems: "center", gap: "8px" }}>
        {icon && (
          <img
            src={icon}
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        )}
        <Text size="sm">{name}</Text>
      </Row>
      <Text size="sm" style={{}}>
        {value}
      </Text>
    </Row>
  );
};

import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { LOAD_POOL_DAY_DATA, LOAD_POOL_HOUR_DATA } from "../graphql";
import { useQuery } from "@apollo/client";
import { usePoolDayData } from "../hooks/usePoolDayData";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Date",
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Price",
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const LiquidityChart = ({ pool }: any) => {
  const { chartLabels, loadedData, loading } = usePoolDayData(pool.id);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Liquidity",
        data: loadedData.map((entry: any) => entry.liquidity),
        borderColor: "rgb(59, 135, 247)",
        backgroundColor: "red",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LiquidityChart;

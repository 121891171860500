import Layout from "antd/es/layout/layout";
import React, { useEffect, useState, useCallback } from "react";
import CreatePool from "../components/CreatePool";
import { Col, ConfigProvider, Input, Row } from "antd";
import PoolCards, { DummyPoolCard } from "../components/PoolCards";
import { poolsState, setPools } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LOAD_POOLS, SEARCH_POOLS } from "../graphql";
import { debounce, orderBy } from "lodash";
import { useAccount } from "wagmi";
import { Select } from "antd";
import mystri from "../assets/mystery.svg";
import { fetchPoolAprs } from "../api/ApiCalls";
import { POOLS_TO_EXCLUDE } from "../constants/pools";

const { Option } = Select;

const Pools = () => {
  const [sortBy, setSortBy] = useState("volumeUSD");
  const [orderDirection, setOrderDirection] = useState("desc");
  const derivedOrderBy = sortBy.includes("_") ? sortBy.split("_")[0] : sortBy;
  const derivedOrderDirection = sortBy.includes("_")
    ? sortBy.split("_")[1]
    : orderDirection;
  const pools = useSelector(poolsState);
  const dispatch = useDispatch();

  const { sm, md, lg, xxl } = useBreakpoint();
  const { loading, data, refetch } = useQuery(LOAD_POOLS, {
    variables: {
      orderBy: derivedOrderBy,
      orderDirection: derivedOrderDirection,
    },
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [poolAprs, setPoolAprs] = useState({});
  const [searchPools, { data: searchData, loading: searchLoading }] =
    useLazyQuery(SEARCH_POOLS);

  const { isConnected } = useAccount();

  useEffect(() => {
    if (data) {
      dispatch(setPools(data.pools));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const loadPoolAprs = async () => {
      // get token addresses of all pools
      const poolAddresses = pools.map((pool: any) => pool.id);
      const poolaprs = await fetchPoolAprs(poolAddresses);
      const aprsByAddress = poolaprs.reduce((acc: any, obj: any) => {
        acc[obj.address] = obj.apr;
        return acc;
      }, {});

      setPoolAprs(aprsByAddress);
    };

    if (pools) {
      loadPoolAprs();
    }
  }, [pools]);

  useEffect(() => {
    if (searchData) {
      const filteredPools = searchData.pools.filter(
        (pool: any) => !POOLS_TO_EXCLUDE.includes(pool.id)
      );

      dispatch(setPools(filteredPools));
    }
  }, [searchData, dispatch]);

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      if (term) {
        searchPools({
          variables: {
            searchTerm: term.toLowerCase(),
            orderBy: derivedOrderBy,
            orderDirection: derivedOrderDirection,
          },
        });
      } else if (data) {
        dispatch(setPools(data.pools));
      }
    }, 500),
    [searchPools, data, dispatch, orderBy, orderDirection]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
  };

  return (
    <Layout
      style={{
        marginTop: "20px",
        backgroundColor: "#252527",
        borderRadius: "12px",
        padding: sm ? "36px" : "12px",
      }}
    >
      <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
        <h5
          style={{
            padding: 0,
            margin: 0,
            color: "white",
            fontSize: sm ? "32px" : "24px",
            fontWeight: "700",
          }}
        >
          Discover Pools
        </h5>
        <div style={{ width: sm ? "" : "100%", marginTop: sm ? "" : "16px" }}>
          {isConnected && <CreatePool refetch={refetch} />}
        </div>
      </Row>
      <Row
        style={{
          margin: "20px 0",
          display: "flex",
          flexDirection: "row",
          gap: "9px",
        }}
      >
        <div
          style={{
            width: md ? "" : "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SortComponent sortBy={sortBy} setSortBy={handleSortChange} />
        </div>

        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorBgContainer: "#050505",
                borderRadius: 8,
                colorTextPlaceholder: "#7C7C82",
                colorText: "#FFF",
                colorBgElevated: "#181819",
                colorBorder: "#050505",
              },
            },
          }}
        >
          <Input
            size="large"
            placeholder="Search by token, address or name"
            onChange={handleSearch}
            value={searchTerm}
            prefix={<img src={mystri} />}
            style={{ maxWidth: md ? "325px" : "100%" }}
          />
        </ConfigProvider>
      </Row>

      <Row style={{ marginTop: "24px" }} gutter={[16, 16]}>
        {pools.length > 0 && !loading && !searchLoading
          ? pools.map((pool: any, index: any) => (
              <Col key={index} span={xxl ? 6 : lg ? 8 : md ? 12 : 24}>
                <PoolCards Pool={pool} index={index} poolAprs={poolAprs} />
              </Col>
            ))
          : Array.from({ length: 3 }).map((_, index) => (
              <Col key={index} span={xxl ? 6 : lg ? 8 : md ? 12 : 24}>
                <DummyPoolCard />
              </Col>
            ))}
      </Row>
    </Layout>
  );
};

export default Pools;

const SortComponent = ({ sortBy, setSortBy }: any) => {
  const handleChange = (value: any) => {
    setSortBy(value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: "#37373C",
            borderRadius: 8,
            colorTextPlaceholder: "#fff",
            optionSelectedBg: "#37373C",
            optionSelectedColor: "#9FC3F9",
            colorText: "#FFF",
            colorBgElevated: "#181819",
            optionPadding: 8,
          },
        },
      }}
    >
      <Select
        //  className="custom-select"
        value={sortBy}
        onChange={handleChange}
        style={{ width: 160 }}
        popupClassName="custom-select-pop"
      >
        <Option value="volumeUSD">Volume</Option>
        <Option value="volumeUSD_asc">Volume (Desc)</Option>
        <Option value="liquidity">Liquidity</Option>
        <Option value="liquidity_asc">Liquidity (Desc)</Option>
        <Option value="createdAtTimestamp">Newest</Option>
        <Option value="createdAtTimestamp_asc">Oldest</Option>
      </Select>
    </ConfigProvider>
  );
};

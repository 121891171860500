import { gql } from "@apollo/client";

export const LOAD_POOLS = gql`
  query getAllPools($orderBy: String!, $orderDirection: String!) {
    pools(
      where: {
        totalValueLockedToken0_gt: "0"
        totalValueLockedToken1_gt: "0"
        id_not_in: [
          "0xb48173e4e5c41fa4d5d3ab03c63fdea71cd5c3de"
          "0x8b14a37376ffb196a5e1ffbb18417d28ab31a863"
          "0x587e1d029b53f75abf17f8252140e54232949272"
          "0x5eef1b335db973302a2b4d5023584db335277b63"
          "0x25e52ab9c3a1b8d1d7e93d6d1ccd66936784c3ef"
          "0x695582a659f500c160797983f554398d5fac8a73"
          "0x9574d91efc9467731b5264eedf4f4971e66f8e50"
          "0x258088b4269dbac779d8c4721fef43735c7dfe32"
          "0xbc34c550d2506a6b2f3129d3a2cf69055d84d432"
          "0xbd94d4e97f430c50fa7b864abfacaa56c28dffa6"
          "0xd0c9e12a99dcd4d01ad33e2a0b40aae8b7146d1f"
          "0x941a84e6cc262cb7bcdc0db8fea36f51b5645a36"
        ]
      }
      first: 100
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      createdAtTimestamp
      createdAtBlockNumber
      feeTier
      liquidity
      sqrtPrice
      token0Price
      token1Price
      tick
      observationIndex
      volumeToken0
      volumeToken1
      volumeUSD
      untrackedVolumeUSD
      feesUSD
      txCount
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedETH
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      liquidityProviderCount
    }
  }
`;

export const SEARCH_POOLS = gql`
  query searchPools(
    $searchTerm: String!
    $orderBy: String!
    $orderDirection: String!
  ) {
    pools(
      where: {
        or: [
          { token0_contains: $searchTerm }
          { token1_contains: $searchTerm }
          { token0_: { name_contains_nocase: $searchTerm } }
          { token0_: { symbol_contains_nocase: $searchTerm } }
          { token0_: { id: $searchTerm } }
          { token1_: { name_contains_nocase: $searchTerm } }
          { token1_: { symbol_contains_nocase: $searchTerm } }
          { token1_: { id: $searchTerm } }
        ]
      }
      first: 100
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      createdAtTimestamp
      createdAtBlockNumber
      feeTier
      liquidity
      sqrtPrice
      token0Price
      token1Price
      tick
      observationIndex
      volumeToken0
      volumeToken1
      volumeUSD
      untrackedVolumeUSD
      feesUSD
      txCount
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedETH
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      liquidityProviderCount
    }
  }
`;

export const LOAD_POOL = gql`
  query GetPool($id: ID!) {
    pool(id: $id) {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      createdAtTimestamp
      createdAtBlockNumber
      feeTier
      liquidity
      sqrtPrice
      token0Price
      token1Price
      tick
      observationIndex
      volumeToken0
      volumeToken1
      volumeUSD
      untrackedVolumeUSD
      feesUSD
      txCount
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedETH
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      liquidityProviderCount
    }
  }
`;

export const LOAD_POOL_TRANSACTIONS = gql`
  query getTransactions($id: ID!) {
    swaps(where: { pool: $id }, orderBy: timestamp, orderDirection: desc) {
      id
      amount0
      amount1
      amountUSD
      recipient
      sender
      timestamp
      origin
    }
    mints(where: { pool: $id }, orderBy: timestamp, orderDirection: desc) {
      id
      amount0
      amount1
      amountUSD
      sender
      timestamp
      origin
    }
    burns(
      where: { pool: $id, amount0_gt: "0" }
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      amount0
      amount1
      amountUSD
      timestamp
      origin
      owner
    }
  }
`;

export const LOAD_POOL_HOUR_DATA = gql`
  query poolHourData($id: ID!) {
    poolHourDatas(where: { pool: $id }, first: 24) {
      close
      feesUSD
      high
      volumeToken0
      volumeToken1
      volumeUSD
      tvlUSD
      token0Price
      token1Price
      open
      low
      liquidity
      sqrtPrice
      periodStartUnix
    }
  }
`;

export const LOAD_POOL_DAY_DATA = gql`
  query poolDayData($id: ID!) {
    poolDayDatas(
      where: { pool: $id }
      orderBy: date
      orderDirection: desc
      first: 25
    ) {
      volumeUSD
      volumeToken1
      volumeToken0
      txCount
      tvlUSD
      token1Price
      token0Price
      sqrtPrice
      tick
      id
      date
      liquidity
      token0Price
      token1Price
    }
  }
`;

export const LOAD_SWAP_TOKENS = gql`
  query loadTokens {
    tokens {
      symbol
      name
      id
      decimals
    }
  }
`;

export const LOAD_PLATFORM_OVERVIEW = gql`
  query platformOverview {
    pools(id_not_in: ["0xf47498edf58639dd1b19e73d49116785590055db"]) {
      totalValueLockedUSD
      volumeUSD
      untrackedVolumeUSD
      totalValueLockedETH
      txCount
      feesUSD
    }
  }
`;

export const LOAD_FEATURED_POOLS = gql`
  query getFeaturedPools {
    pools(
      where: {
        totalValueLockedToken0_gt: "0"
        totalValueLockedToken1_gt: "0"
        id_not_in: [
          "0xb48173e4e5c41fa4d5d3ab03c63fdea71cd5c3de"
          "0x8b14a37376ffb196a5e1ffbb18417d28ab31a863"
          "0x587e1d029b53f75abf17f8252140e54232949272"
          "0x5eef1b335db973302a2b4d5023584db335277b63"
          "0x25e52ab9c3a1b8d1d7e93d6d1ccd66936784c3ef"
          "0x695582a659f500c160797983f554398d5fac8a73"
          "0x9574d91efc9467731b5264eedf4f4971e66f8e50"
        ]
      }
      orderBy: volumeUSD
      first: 3
      orderDirection: desc
    ) {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        volume
        volumeUSD
        untrackedVolumeUSD
        feesUSD
        txCount
        poolCount
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        derivedETH
        whitelistPools {
          id
        }
      }
      createdAtTimestamp
      createdAtBlockNumber
      feeTier
      liquidity
      sqrtPrice
      token0Price
      token1Price
      tick
      observationIndex
      volumeToken0
      volumeToken1
      volumeUSD
      untrackedVolumeUSD
      feesUSD
      txCount
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedETH
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      liquidityProviderCount
    }
  }
`;

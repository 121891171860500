import axios from "../axios";
import { PoolType } from "../../utils/types";
import Web3 from "web3";

interface PoolData {
  transaction_hash: string;
}

export const GetAllPool = async () => {
  try {
    const endPoint = "/pools/";
    const res = await axios.get<PoolType[]>(endPoint);
    if (res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("error Get Pool Api ", err);
    return Promise.reject(err);
  }
};

export const getTokens = async (search?: string) => {
  try {
    const endPoint = `${process.env.REACT_APP_SHIDO_TOKENS_BASE_URL}${
      search ? "&value=" + search : ""
    }`;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.data.tokens.filter(
        (token: any) => token.type === "ERC-20"
      );
    }
  } catch (err) {
    console.log("error getTokens  Api ", err);
    return Promise.reject(err);
  }
};

export const searchTokens = async (search?: string) => {
  try {
    const endPoint = `${process.env.REACT_APP_SHIDO_TOKENS_BASE_URL}${
      search ? "&value=" + search : ""
    }`;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.data;
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTokenImage = async (address: string) => {
  try {
    const endPoint = "tokens/image/" + address;
    const res = await axios.get<any>(endPoint);
    if (res?.data) {
      return res.data.url;
    }
  } catch (err) {
    return "";
  }
};

export const getRoute = async (
  fromToken: string,
  toToken: string,
  fromAmount: any,
  recipient: `0x${string}` | undefined,
  swapType = "exactIn",
  slippage = 0.5
) => {
  try {
    const endPoint = `${process.env.REACT_APP_API_HOST}/get-route/`;

    const params = {
      fromToken,
      toToken,
      fromAmount,
      recipient,
      swapType,
      slippage,
    };

    const response = await axios.get(endPoint, { params });

    if (response?.data) {
      return response.data;
    } else {
      console.log(response);
      throw new Error("Failed to fetch  getRoute");
    }
  } catch (err) {
    console.error("Error fetching getRoute :", err);
    throw err;
  }
};

export const fetchTokenPrices = async (tokenAddresses: any) => {
  try {
    const queryParams = new URLSearchParams();
    tokenAddresses.forEach((address: any) =>
      queryParams.append("addresses", address)
    );

    const response = await axios.get(
      `/token-prices/?${queryParams.toString()}`
    );

    return response.data;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};

export const fetchTokenImages = async (tokenAddresses: any) => {
  // convert all addresses to checksum
  const checksumAddresses = tokenAddresses.map((address: any) => {
    // if this fails then exclude address from the list
    return Web3.utils.toChecksumAddress(address);
  });

  try {
    const response = await axios.post(
      process.env.REACT_APP_SHIDO_TOKEN_IMAGE_URL || "",
      {
        address: checksumAddresses,
      }
    );

    return response.data?.data?.images;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};

export const getSwapTokens = async (search?: string) => {
  try {
    const response = await axios.get(`/tokens/`);

    return response.data;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};

export const fetchPoolAprs = async (tokenAddresses: any) => {
  // convert all addresses to checksum

  try {
    const response = await axios.post("/pool-apr/", {
      addresses: tokenAddresses,
    });

    return response.data;
  } catch (err) {
    console.error("Error fetching token prices:", err);
    throw err;
  }
};

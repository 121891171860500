import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import { Button, Col, ConfigProvider, Drawer, Row } from "antd";
import { GetChallenge, WalletLogin } from "../api/ApiCalls";
import { useSignMessage } from "wagmi";
import { useDispatch } from "react-redux";
import { login, logout } from "../redux/reducers";
import logo from "../assets/Svg/logo.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useNavigate } from "react-router-dom";
import MenuBars from "../assets/FiMenu.png";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useError } from "../contexts/ErrorContext";

const Header = () => {
  // const { address, connector } = useAccount();
  // const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const dispatch = useDispatch();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  // const { setError } = useError();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  // const HandleDissconnect = () => {
  //   dispatch(logout());
  //   disconnect();
  // };

  // const SignMessage = async (message: string) => {
  //   try {
  //     const signature = await signMessageAsync({ message });
  //     return signature;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  // interface SignedData {
  //   wallet_address: string;
  //   signature: string;
  //   challenge: string;
  // }

  // useEffect(() => {
  //   if (address && !localStorage.getItem("accessToken") && connector) {
  //     let challenge = "";

  //     GetChallenge(address)
  //       .then((res) => {
  //         challenge = res?.data;
  //         SignMessage(res.data)
  //           .then((signature) => {
  //             const payload: SignedData = {
  //               wallet_address: address,
  //               signature,
  //               challenge,
  //             };

  //             WalletLogin(payload)
  //               .then((value) => {
  //                 const payload = {
  //                   user: value.wallet_address,
  //                   isAuthenticated: true,
  //                   token: value.access_token,
  //                   refreshToken: value.refresh_token,
  //                 };

  //                 dispatch(login(payload));
  //                 localStorage.setItem("accessToken", value.access_token);
  //                 localStorage.setItem("refreshToken", value.refresh_token);
  //               })
  //               .catch((error: any) => {
  //                 setError({
  //                   message: error.message,
  //                   type: "error",
  //                 });
  //                 throw error;
  //               });
  //           })
  //           .catch((error: any) => {
  //             setError({
  //               message: error.message,
  //               type: "error",
  //             });
  //             throw error;
  //           });
  //       })
  //       .catch((error: any) => {
  //         setError({
  //           message: error.message,
  //           type: "error",
  //         });
  //         HandleDissconnect();
  //       });
  //   }
  // }, [address, connector]);

  return (
    <>
      <Row
        style={{
          display: "flex",
          backgroundColor: "#252527",
          justifyContent: "space-between",
          alignItems: "center",
          padding: sm ? "12px 36px" : "10px 16px",
          borderRadius: "12px",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            gap: "64px",
            width: "60%",
          }}
        >
          <img
            src={logo}
            style={{ width: "117px", height: "48px", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          />
          {sm && <MyMenu mode="horizontal" />}
        </Col>
        {sm ? (
          <WalletButton />
        ) : (
          <img
            src={MenuBars}
            style={{ cursor: "pointer" }}
            onClick={showDrawer}
          />
        )}
      </Row>
      <Siderbar open={openDrawer} onClose={onClose} />
    </>
  );
};

export default Header;

interface WalletconnectProp {
  FullWidth?: boolean;
  onCloseDrawer?: () => void;
}

const WalletButton = (props: WalletconnectProp) => {
  const { FullWidth, onCloseDrawer } = props;
  const { open } = useWeb3Modal();
  const { address, connector } = useAccount();
  return (
    <Col style={{ display: "flex", alignItems: "center" }}>
      {!address && (
        <Button
          type="primary"
          onClick={() => {
            if (onCloseDrawer) {
              onCloseDrawer();
            }
            open({ view: "Networks" });
          }}
          style={{ width: FullWidth ? "100%" : "" }}
        >
          Connect Wallet
        </Button>
      )}
      {address && (
        <>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: "#37373C",
                  colorBorder: "transparent",
                  colorText: "white",
                  defaultHoverBg: "#46464d",
                  defaultHoverBorderColor: "#46464d",
                  defaultHoverColor: "white",
                },
              },
            }}
          >
            <Button
              onClick={() => {
                if (onCloseDrawer) {
                  onCloseDrawer();
                }
                open();
              }}
              style={{
                marginLeft: FullWidth ? "" : "16px",
                width: FullWidth ? "100%" : "",
                marginBottom: FullWidth ? "36px" : "",
              }}
              type="default"
            >
              {shortenString(address)}
            </Button>
          </ConfigProvider>
        </>
      )}
    </Col>
  );
};

function shortenString(str: any, maxLength = 8) {
  if (str.length <= maxLength * 2) {
    return str;
  }
  const halfLength = Math.floor(maxLength / 2);
  return str.slice(0, halfLength) + "..." + str.slice(-halfLength);
}

interface MyMenuProp {
  mode: "horizontal" | "inline";
  onCloseDrawer?: () => void;
}
const MyMenu = (prop: MyMenuProp) => {
  const { mode, onCloseDrawer } = prop;
  const [current, setCurrent] = useState("mail");
  const navigate = useNavigate();
  const { address } = useAccount();

  const items: MenuProps["items"] = [
    {
      label: "Home",
      key: "home",
      onClick: () => {
        navigate("/");
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
    },
    {
      label: "Pools",
      key: "dashboard",
      onClick: () => {
        navigate("/pools");
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
    },
    {
      label: "Swap",
      key: "/swap",
      onClick: () => {
        navigate("/swap");
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
    },
    {
      label: "Wrap/Unwrap",
      key: "/wrap-unwrap",
      onClick: () => {
        navigate("/wrap-unwrap");
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
    },
    // {
    //   label: "Wrp/Unwrap",
    //   key: "/wrp-unwrap",
    //   onClick: () => {
    //     navigate("/wrap-unwrap");
    //     if (onCloseDrawer) {
    //       onCloseDrawer();
    //     }
    //   },
    // },
    ...(address
      ? [
          {
            label: "My Positions",
            key: "/my-positions",
            onClick: () => {
              navigate("/my-positions");
              if (onCloseDrawer) {
                onCloseDrawer();
              }
            },
          },
        ]
      : []),
    {
      label: "FAQs",
      key: "/faq",
      onClick: () => {
        navigate("/faq");
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
    },
  ];

  return (
    <Menu
      // selectedKeys={[current]}
      mode={mode}
      items={items}
      style={{ width: mode === "horizontal" ? "100%" : "100%" }}
    />
  );
};

interface SiderbarProp {
  open: boolean;
  onClose: () => void;
}

const Siderbar = (props: SiderbarProp) => {
  const { onClose, open } = props;

  return (
    <Drawer
      placement={"left"}
      closable={false}
      onClose={onClose}
      open={open}
      width={294}
      styles={{
        content: { padding: "36px 20px 20px 20px" },
        body: {
          padding: "0px",
        },
      }}
    >
      <WalletButton FullWidth onCloseDrawer={onClose} />
      <MyMenu mode="inline" onCloseDrawer={onClose} />
    </Drawer>
  );
};

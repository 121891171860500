export const POOLS_TO_EXCLUDE = [
  "0xb48173e4e5c41fa4d5d3ab03c63fdea71cd5c3de",
  "0x8b14a37376ffb196a5e1ffbb18417d28ab31a863",
  "0x587e1d029b53f75abf17f8252140e54232949272",
  "0x5eef1b335db973302a2b4d5023584db335277b63",
  "0x25e52ab9c3a1b8d1d7e93d6d1ccd66936784c3ef",
  "0x695582a659f500c160797983f554398d5fac8a73",
  "0x9574d91efc9467731b5264eedf4f4971e66f8e50",
  "0x258088b4269dbac779d8c4721fef43735c7dfe32",
  "0xbc34c550d2506a6b2f3129d3a2cf69055d84d432",
  "0xbd94d4e97f430c50fa7b864abfacaa56c28dffa6",
  "0xd0c9e12a99dcd4d01ad33e2a0b40aae8b7146d1f",
  "0x941a84e6cc262cb7bcdc0db8fea36f51b5645a36",
];

import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { Alert } from "antd";

interface ErrorData {
  message: string;
  description?: string;
  type?: "error" | "warning" | "info" | "success";
}

interface ErrorContextProps {
  error: ErrorData | null;
  setError: (error: ErrorData | null) => void;
}

const ErrorContext = createContext<ErrorContextProps>({
  error: null,
  setError: () => {},
});

export const useError = () => useContext(ErrorContext);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<ErrorData | null>(null);

  // Added useEffect to set a timer for auto-closing the alert after 3 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
      {error && (
        <div className="centered-alert">
          <Alert
            message={error.message}
            description={error.description}
            type={error?.type || "error"}
            showIcon
            closable={false}
            onClose={() => setError(null)}
          />
        </div>
      )}
    </ErrorContext.Provider>
  );
};

import { useEffect } from "react";
// import { useConnect } from "wagmi";
// import { CHAIN_ID } from "../constants";
// import { InjectedConnector } from "wagmi/connectors/injected";
import { useWeb3Modal, useWeb3ModalEvents } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

const useShidoAutoConnect = () => {
  const isShidoWallet = window?.ethereum?.isShidoWallet || false;
  // const { connect } = useConnect();
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  useEffect(() => {
    console.log("isShidoWallet", isShidoWallet);
    if (isShidoWallet && !address) {
      open({ view: "Connect" });
      // connect({ chainId: CHAIN_ID, connector: new InjectedConnector() });
    }
  }, [isShidoWallet]);
};

export default useShidoAutoConnect;

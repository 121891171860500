import { ethers } from "ethers";
import JSBI from "jsbi";
import {
  SWAP_ROUTER_V2,
  MAX_FEE_PER_GAS,
  MAX_PRIORITY_FEE_PER_GAS,
} from "../constants";
import { Token } from "@shidoglobal/sdk-core";
import { BigNumber } from "ethers";
import Web3 from "web3";
import { erc20ABI } from "wagmi";
import { BigNumber as BigNumberJs } from "bignumber.js";

export async function setSwapRouterApproval(
  token: Token,
  web3: Web3,
  amountToApprove: string,
  walletAddress: any
): Promise<any> {
  try {
    const tokenContract = new web3.eth.Contract(erc20ABI, token.address);

    return await tokenContract.methods
      .approve(SWAP_ROUTER_V2, amountToApprove)
      .send({ from: walletAddress });
  } catch (e) {
    console.error(e);
    return false;
  }
}

// Function to execute a swap route
export async function executeRoute(
  provider: Web3,
  tokenAddress: any,
  inputAmount: any,
  route: any,
  address: `0x${string}` | undefined
) {
  // Check if provider and signer are available
  if (!provider) {
    throw new Error("Cannot execute a trade without a connected wallet");
  }

  // Check if route is available for execution
  if (!route || !route.methodParameters) {
    throw new Error("Cannot execute as no route for execution");
  }

  // const gasPrice = BigNumber.from(parseInt(route.gasPriceWei.hex));
  // const gasLimit = BigNumber.from(parseInt(route.estimatedGasUsed.hex));

  // Construct transaction parameters
  const transactionParam: any = {
    data: route.methodParameters.calldata,
    to: SWAP_ROUTER_V2,
    value: route.methodParameters.value,
    from: address,
    // gasPirce: gasPrice.toHexString(),
  };

  // Execute transaction
  const res = await provider.eth.sendTransaction(transactionParam);

  return res;
}

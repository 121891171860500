import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { CloseOutlined } from "@ant-design/icons";
import BigNumber from "bignumber.js";
import {
  createPosition,
  getPositionManagerAllowance,
  getPositionManagerApproval,
  getTokenBalance,
} from "../utils/poolFunctions";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import Web3 from "web3";
import { PoolDataType, formatNumberUniversal } from "../utils";
import { fromReadableAmount } from "../utils/tradeUtils";
import Text from "../components/Text";
import fiCheckCircle from "../assets/FiCheckCircle.svg";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { CHAIN_ID } from "../constants";
import PriceRange from "./HomeComponents/PriceRange";

import { useWaitForTransaction } from "wagmi";
import { fetchTokenPrices } from "../api/ApiCalls";
import { useError } from "../contexts/ErrorContext";
import { nearestUsableTick } from "@uniswap/v3-sdk";
interface AddLiquidityInterface {
  isModalOpen: any;
  handleOk: any;
  handleCancel: any;
  onChange: any;
  coin1: any;
  coin2: any;
  pool: any;
  token0Image: string;
  token1Image: string;
  poolData: PoolDataType;
  exchangeRate: any;
  getPoolFromApi: any;
  transactionsQuery: any;
}
const AddLiquidityModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  pool,
  token0Image,
  token1Image,
  poolData,
  exchangeRate,
  getPoolFromApi,
  transactionsQuery,
}: AddLiquidityInterface) => {
  const { xs, sm } = useBreakpoint();

  const [loading, setLoading] = useState<boolean>(false);
  const [addPositionLoading, setaddPositionLoading] = useState<boolean>(false);
  const [isPositionSuccess, setIsPositionSuccess] = useState<boolean>(false);
  const [token0Balance, setToken0Balance] = useState<number>(0);
  const [token1Balance, setToken1Balance] = useState<number>(0);
  const [token0DepositAmount, setToken0DepositAmount] = useState<number>(0);
  const [token1DepositAmount, setToken1DepositAmount] = useState<number>(0);
  const [lowPriceRange, setLowPriceRange] = useState<null | number>(null);
  const [highPriceRange, setHighPriceRange] = useState<null | number>(null);
  const [token0DollarValue, setToken0DollarValue] = useState<number>(0);
  const [token1DollarValue, setToken1DollarValue] = useState<number>(0);
  const { open } = useWeb3Modal();
  const [token0Allowance, setToken0Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const [token1Allowance, setToken1Allowance] = useState<BigNumber>(
    BigNumber(0)
  );
  const { connector, address } = useAccount();
  const { chain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  const [txHash, setTxHash] = useState("");
  const [fullPriceRange, setFullPriceRange] = useState(true);

  const { status, isError } = useWaitForTransaction({
    confirmations: 2,
    hash: txHash as any,
    enabled: !!txHash,
  });
  const { setError } = useError();

  useEffect(() => {
    const getPrices = async () => {
      try {
        const addresses = [pool.token0.id, pool.token1.id];
        const prices = await fetchTokenPrices(addresses);

        if (prices.prices) {
          setToken0DollarValue(prices.prices[pool.token0.id].toLowerCase());
          setToken1DollarValue(prices.prices[pool.token1.id].toLowerCase());
        }
      } catch (e: any) {
        setError({
          message: e.message,
          type: "error",
        });
      }
    };
    if (pool) {
      getPrices();
    }
  }, [pool, setError]);

  useEffect(() => {
    if (status === "success" && !isError) {
      setError({
        message: "Position added successfully",
        type: "success",
      });
      try {
        transactionsQuery.refetch();
      } catch (err: any) {
        setError({
          message: err.message,
          type: "error",
        });
      }
      getPoolFromApi();
      getTokenBalances();
      setIsPositionSuccess(true);
    } else if (isError) {
      setError({
        message: "Failed to confirm transaction",
        type: "error",
      });
    }

    if (status === "success" || isError) {
      setTxHash("");
      setaddPositionLoading(false);
    }
  }, [status, isError]);

  const ensureCorrectNetwork = async () => {
    if (!pendingChainId && !isLoading && chain?.id !== CHAIN_ID) {
      switchNetwork?.(CHAIN_ID);
    }
  };

  const getTokenBalances = async () => {
    if (pool.token0.id) {
      const balance = await getTokenBalance(address, pool.token0.id);
      setToken0Balance(Number(balance));
    } else {
      setToken0Balance(0);
    }

    if (pool.token1.id) {
      const balance = await getTokenBalance(address, pool.token1.id);
      setToken1Balance(Number(balance));
    } else {
      setToken1Balance(0);
    }
  };

  useEffect(() => {
    if (pool && address) {
      ensureCorrectNetwork();

      if (chain && chain.id === CHAIN_ID) {
        getTokenBalances();
      }
    }
  }, [pool, address, chain, pendingChainId]);

  const tickToPrice = (
    tick: number,
    token0Decimals: number,
    token1Decimals: number
  ) => {
    const decimalDifference = token0Decimals - token1Decimals;
    const sqrtRatioX96 = Math.pow(1.0001, tick / 2);
    const price = sqrtRatioX96 ** 2 * 10 ** decimalDifference;
    return new BigNumber(price);
  };

  const getTickSpacing = (fee: number): number => {
    switch (fee) {
      case 100: // 0.01%
        return 1;
      case 500: // 0.05%
        return 10;
      case 3000: // 0.3%
        return 60;
      case 10000: // 1%
        return 200;
      default:
        throw new Error(`Unsupported fee tier: ${fee}`);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (!pool) return;

      const tickSpacing = getTickSpacing(Number(pool.feeTier));
      const rangeTicks = tickSpacing * 4;

      const currentTick = Number(pool.tick);
      if (currentTick) {
        const lowTick = currentTick - rangeTicks;
        const highTick = currentTick + rangeTicks;

        const lowPrice = tickToPrice(
          lowTick,
          Number(pool.token0.decimals),
          Number(pool.token1.decimals)
        );
        const highPrice = tickToPrice(
          highTick,
          Number(pool.token0.decimals),
          Number(pool.token1.decimals)
        );

        handlelowPriceRangeChange(lowPrice.toNumber());
        handleHighPriceRangeChange(highPrice.toNumber());
      }
    };

    if (pool && address && !fullPriceRange) {
      loadData();
    }
  }, [
    pool,
    address,
    pool.token0.decimals,
    pool.token1.decimals,
    fullPriceRange,
  ]);

  const addPosition = async () => {
    setaddPositionLoading(true);
    try {
      const web3 = new Web3(await connector?.getProvider());

      // get token decimals
      const token0Decimals = Number(pool.token0.decimals);
      const token1Decimals = Number(pool.token1.decimals);

      const token0Amount: any = fromReadableAmount(
        BigNumber(token0DepositAmount),
        Number(token0Decimals)
      );

      const token1Amount: any = fromReadableAmount(
        BigNumber(token1DepositAmount),
        Number(token1Decimals)
      );

      const res: any = await createPosition(
        address,
        poolData?.fee ? poolData?.fee : 0,
        pool.token0.id,
        pool.token1.id,
        web3,
        token0Amount,
        token1Amount,
        lowPriceRange,
        highPriceRange
      );

      if (res && res.transactionHash) {
        setTxHash(res.transactionHash);
      } else {
        setError({
          message: "Failed to add position",
          type: "error",
        });
        setaddPositionLoading(false);
      }
    } catch (error: any) {
      setError({
        message: "Failed to add position",
        type: "error",
        description: error?.message,
      });
      setaddPositionLoading(false);
    }
  };

  const handleMaxToken0 = () => {
    setToken0DepositAmount(token0Balance);

    const calculatedToken1 = token0Balance * exchangeRate.token0;
    setToken1DepositAmount(
      Number(calculatedToken1.toFixed(Number(pool.token1.decimals)))
    );
  };

  const handleMaxToken1 = () => {
    setToken1DepositAmount(token1Balance);

    const calculatedToken0 = token1Balance * exchangeRate.token1;
    setToken0DepositAmount(
      Number(calculatedToken0.toFixed(Number(pool.token0.decimals)))
    );
  };

  const handleInputChange = (value: any, index: any) => {
    if (index === 0) {
      setToken0DepositAmount(value);
      if (value) {
        const calculatedToken1 = value * exchangeRate.token0;

        setToken1DepositAmount(
          Number(calculatedToken1.toFixed(Number(pool.token1.decimals)))
        );
      } else {
        setToken1DepositAmount(0);
      }
    } else if (index === 1) {
      setToken1DepositAmount(value);
      if (value) {
        const calculatedToken0 = value * exchangeRate.token1;
        setToken0DepositAmount(
          Number(calculatedToken0.toFixed(Number(pool.token0.decimals)))
        );
      } else {
        setToken0DepositAmount(0);
      }
    }
  };

  const getToken0Allowance = async () => {
    if (pool.token0.id && address) {
      const allowance = await getPositionManagerAllowance(
        address,
        pool.token0.id
      );

      setToken0Allowance(allowance);
    }
  };

  const getToken1Allowance = async () => {
    if (pool.token1.id && address) {
      const allowance = await getPositionManagerAllowance(
        address,
        pool.token1.id
      );

      setToken1Allowance(allowance);
    }
  };

  useEffect(() => {
    if (
      token0Allowance?.isEqualTo(BigNumber(0)) ||
      token0Allowance?.isGreaterThan(BigNumber(token0DepositAmount))
    ) {
      ensureCorrectNetwork();

      if (chain?.id === CHAIN_ID) getToken0Allowance();
    }
  }, [pool, token0DepositAmount, address, chain]);

  useEffect(() => {
    if (
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isGreaterThan(BigNumber(token1DepositAmount))
    ) {
      ensureCorrectNetwork();
      if (chain?.id === CHAIN_ID) getToken1Allowance();
    }
  }, [pool, token1DepositAmount, address, chain]);

  const allowAddPosition = () => {
    return (
      loading ||
      token0Allowance.isEqualTo(BigNumber(0)) ||
      token0Allowance.isLessThan(BigNumber(token0DepositAmount)) ||
      token1Allowance.isEqualTo(BigNumber(0)) ||
      token1Allowance.isLessThan(BigNumber(token1DepositAmount)) ||
      BigNumber(token0DepositAmount).isEqualTo(0) ||
      BigNumber(token1DepositAmount).isEqualTo(0) ||
      BigNumber(token0Balance).isLessThan(token0DepositAmount) ||
      BigNumber(token1Balance).isLessThan(token1DepositAmount)
    );
  };

  const handlelowPriceRangeChange = (price: number | null) => {
    if (price || price === 0) {
      setLowPriceRange(price);
    }
  };

  const handleHighPriceRangeChange = (price: number | null) => {
    if (price || price === 0) {
      setHighPriceRange(price);
    }
  };

  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setLowPriceRange(null);
      setHighPriceRange(null);
    }
    setFullPriceRange(checked);
  };

  const resetModal = () => {
    setLoading(false);
    setaddPositionLoading(false);
    setIsPositionSuccess(false);
    setToken0DepositAmount(0);
    setToken1DepositAmount(0);
    setLowPriceRange(null);
    setHighPriceRange(null);
    setTxHash("");
    setFullPriceRange(true);
    getTokenBalances();
  };

  return (
    <Row>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#252527",
            },
            InputNumber: {
              colorTextPlaceholder: "#9B9CA3",
              fontSize: 24,
              paddingInline: 2,
            },
          },
        }}
      >
        <Modal
          className="abcccccc"
          styles={{ content: { padding: "24px" } }}
          centered
          open={isModalOpen}
          onOk={() => {
            handleOk();
            setIsPositionSuccess(false);
          }}
          onCancel={() => {
            handleCancel();
            setIsPositionSuccess(false);
          }}
          footer={false}
          closable={false}
          width={sm ? 445 : "100%"}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <h4
              style={{
                fontSize: "20px",
                fontFamily: "600",
                color: "white",
                margin: "0",
              }}
            >
              Add liquidity
            </h4>
            <CloseOutlined
              style={{ color: "white", height: "24px", width: "24px" }}
              onClick={handleCancel}
            />
          </Row>

          <Row
            style={{ width: "100%", marginBottom: "28px", marginTop: "24px" }}
          >
            <div
              style={{
                width: "100%",
                marginBottom: "15px",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Text
                size="sm"
                style={{
                  fontWeight: "500",
                  marginRight: "8px",
                }}
              >
                Use full price range
              </Text>
              <Switch onChange={onSwitchChange} checked={fullPriceRange} />
            </div>
            <PriceRange
              colorchild="#37373C"
              handlelowPriceRangeChange={handlelowPriceRangeChange}
              lowPriceRange={lowPriceRange}
              handleHighPriceRangeChange={handleHighPriceRangeChange}
              highPriceRange={highPriceRange}
              token0Symbol={pool.token0.symbol}
              token1Symbol={pool.token1.symbol}
              fee={Number(pool.feeTier)}
              token0Decimals={Number(pool.token0.decimals)}
              token1Decimals={Number(pool.token1.decimals)}
              fullPriceRange={fullPriceRange}
            />
          </Row>
          <Text size="sm" style={{ fontWeight: "500", width: "100%" }}>
            Add liquidity
          </Text>
          {!isPositionSuccess ? (
            <Row style={{ width: "100%" }}>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "8px",
                  backgroundColor: "#37373C",
                  borderRadius: "12px",
                  padding: "16px",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputNumber
                    type="number"
                    onChange={(value) => {
                      handleInputChange(value, 0);
                    }}
                    value={token0DepositAmount}
                    placeholder="0"
                    style={{
                      marginLeft: "-3px",
                      width: xs ? "100px" : "150px",
                    }}
                  />

                  <h5
                    style={{
                      color: "#9B9CA3",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    $
                    {Number(token0DepositAmount) && Number(token0DollarValue)
                      ? formatNumberUniversal(
                          String(
                            Number(token0DepositAmount) *
                              Number(token0DollarValue)
                          )
                        )
                      : "0.00"}
                  </h5>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Row
                    style={{
                      backgroundColor: "#45454A",
                      borderRadius: "25px",
                      padding: "5px 12px 5px 5px",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt={pool.token0.symbol}
                      src={token0Image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <h5
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0",
                      }}
                    >
                      {pool?.token0?.symbol}
                    </h5>
                  </Row>
                  <Row style={{ gap: "12px", alignItems: "center" }}>
                    <h5
                      style={{
                        margin: 0,
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Balance : {formatNumberUniversal(String(token0Balance))}
                    </h5>
                    <div
                      style={{
                        backgroundColor: "#3B87F7",
                        borderRadius: "6px",
                        padding: " 2px 7px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={handleMaxToken0}
                    >
                      Max
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "8px",
                  backgroundColor: "#37373C",
                  borderRadius: "12px",
                  padding: "16px",
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputNumber
                    type="number"
                    onChange={(value) => {
                      handleInputChange(value, 1);
                    }}
                    style={{
                      marginLeft: "-3px",
                      width: xs ? "100px" : "150px",
                    }}
                    value={formatNumberUniversal(String(token1DepositAmount))}
                    placeholder="0"
                  />
                  <h5
                    style={{
                      color: "#9B9CA3",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    $
                    {Number(token1DepositAmount) && Number(token1DollarValue)
                      ? formatNumberUniversal(
                          String(
                            Number(token1DepositAmount) *
                              Number(token1DollarValue)
                          )
                        )
                      : "0.00"}
                  </h5>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Row
                    style={{
                      backgroundColor: "#45454A",
                      borderRadius: "25px",
                      padding: "5px 12px 5px 5px",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt={pool.token1.symbol}
                      src={token1Image}
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <h5
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0",
                      }}
                    >
                      {pool?.token1?.symbol}
                    </h5>
                  </Row>
                  <Row style={{ gap: "12px", alignItems: "center" }}>
                    <h5
                      style={{
                        margin: 0,
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Balance : {formatNumberUniversal(String(token1Balance))}
                    </h5>
                    <div
                      style={{
                        backgroundColor: "#3B87F7",
                        borderRadius: "6px",
                        padding: " 2px 7px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={handleMaxToken1}
                    >
                      Max
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                {token0Allowance.isLessThan(BigNumber(token0DepositAmount)) && (
                  <Button
                    loading={loading}
                    type="primary"
                    style={{ width: "100%", marginTop: "36px" }}
                    onClick={async () => {
                      setLoading(true);
                      const maxApprovalValue = new BigNumber(
                        "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
                      );
                      const scaledValue = maxApprovalValue
                        .multipliedBy(new BigNumber(10).pow(18))
                        .integerValue();

                      const approvalResult = await getPositionManagerApproval(
                        address,
                        pool.token0.id,
                        scaledValue
                      );

                      if (approvalResult && approvalResult.transactionHash) {
                        setToken0Allowance(BigNumber(scaledValue));
                      }
                      setLoading(false);
                    }}
                  >
                    Approve {pool.token0.symbol}
                  </Button>
                )}

                {token1Allowance.isLessThan(BigNumber(token1DepositAmount)) && (
                  <Button
                    loading={loading}
                    type="primary"
                    style={{ width: "100%", marginTop: "10px" }}
                    onClick={async () => {
                      setLoading(true);
                      const maxApprovalValue = new BigNumber(
                        "115792089237316195423570985008687907853269984665640564039457.584007913129639935"
                      );
                      const scaledValue = maxApprovalValue
                        .multipliedBy(new BigNumber(10).pow(18))
                        .integerValue();

                      const approvalResult = await getPositionManagerApproval(
                        address,
                        pool.token1.id,
                        scaledValue
                      );

                      if (approvalResult && approvalResult.transactionHash) {
                        setToken1Allowance(BigNumber(scaledValue));
                      }
                      setLoading(false);
                    }}
                  >
                    Approve {pool.token1.symbol}
                  </Button>
                )}

                {address ? (
                  <Button
                    type="primary"
                    style={{ width: "100%", marginTop: "36px" }}
                    onClick={addPosition}
                    // disabled={allowAddPosition()}
                    loading={addPositionLoading}
                  >
                    Add Position
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      open({ view: "Networks" });
                      handleCancel();
                      setIsPositionSuccess(false);
                    }}
                    style={{ width: "100%", marginTop: "36px" }}
                  >
                    Connect Wallet
                  </Button>
                )}

                {(loading || addPositionLoading) && (
                  <Text
                    size="xs"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "12px",
                    }}
                  >
                    Processing, this may take some time...
                  </Text>
                )}
              </Row>
            </Row>
          ) : (
            <>
              <Row
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#37373C",
                  flexDirection: "column",
                  gap: "8px",
                  padding: "30px 0px",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <img src={fiCheckCircle} alt={"success"} />
                <Text size="md">Position has been added successfully!</Text>
              </Row>
              <Button
                type="primary"
                style={{ width: "100%", marginTop: "32px" }}
                onClick={() => {
                  resetModal();
                }}
              >
                Add more liquidity
              </Button>
            </>
          )}
        </Modal>
      </ConfigProvider>
    </Row>
  );
};

export default AddLiquidityModal;

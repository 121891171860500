import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { colors } from "./constants";

export const renderHtmlString = (str: string) => (
  <div dangerouslySetInnerHTML={{ __html: str }} />
);

export const copyable = (color: string = colors.primary500) => ({
  icon: [
    <CopyOutlined style={{ color }} />,
    <CheckOutlined style={{ color }} />,
  ],
});

export function openUrlInNewTab(url: string) {
  const newTab = window.open(url, "_blank", "noopener,noreferrer");
  if (newTab) newTab.opener = null;
}

export function leftPad(number: number, targetLength: number) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function addEllipsisToText(address: any, beforeLength: number) {
  const length = address.length;
  const start = address.slice(0, beforeLength);
  const end = address.slice(length - 3, length);
  return `${start}...${end}`;
}

export const getRemInPixels = (rem: number = 1) => {
  const temporaryElement = document.createElement("div");
  temporaryElement.style.width = `${rem}rem`;
  document.body.appendChild(temporaryElement);
  const itemWidthPixels = temporaryElement.offsetWidth;
  document.body.removeChild(temporaryElement);
  return itemWidthPixels;
};

export const maskAddressOrEmail = (input: any): string => {
  if (!input) return "";
  const MASK_CHAR = "*";
  if (input.includes("@")) {
    // For email addresses
    const [localPart, domain] = input.split("@");
    const maskedLocalPart =
      localPart.substring(0, 2) + MASK_CHAR.repeat(4) + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
  } else if (input.startsWith("0x")) {
    // For wallet addresses
    const maskedAddress =
      input.substring(0, 6) + MASK_CHAR.repeat(4) + input.slice(-4);
    return maskedAddress;
  }

  return input; // Return unchanged if not recognized as email or wallet address
};

export function formatNumber(value: number) {
  const numberString = String(value);

  if (/^-?\d+\.0+$/.test(numberString)) {
    return value.toFixed(6);
  }

  if (/^-?\d+\.0*[1-9]\d*$/.test(numberString)) {
    return value.toFixed(5);
  }

  return value.toFixed(6);
}

export function delayExecution(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatNumberUniversal(input: string): string {
  try {
    const num = parseFloat(input);

    if (isNaN(num)) {
      throw new Error("Invalid number input");
    }

    // Determine the number of decimal places based on the significant digits
    let decimalPlaces: number;

    if (num >= 1) {
      decimalPlaces = 2;
    } else {
      const numStr = num.toString();
      const parts = numStr.split(".");
      const decimalPart = parts[1] || "";
      decimalPlaces = 0;

      for (let char of decimalPart) {
        decimalPlaces++;
        if (char !== "0") {
          decimalPlaces++;
          break;
        }
      }
    }

    // Format the number
    const formattedNumber = num.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    return parseFloat(formattedNumber).toString();
  } catch (e) {
    return "0";
  }
}

export function formatLiquidity(input: number): string {
  try {
    const units = ["", "K", "M", "B", "T"];
    let unitIndex = 0;
    let num = input;

    while (num >= 1000 && unitIndex < units.length - 1) {
      num /= 1000;
      unitIndex++;
    }

    return `${num.toFixed(2)}${units[unitIndex]}`;
  } catch (e) {
    console.error("Error formatting liquidity:", e);
    return "0";
  }
}

import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LOAD_POOL_DAY_DATA } from "../graphql";
import { PoolDayData } from "../types/pools";

export const usePoolDayData = (id: string) => {
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [loadedData, setLoadedData] = useState<PoolDayData[]>([]);

  const dayDataQuery = useQuery(LOAD_POOL_DAY_DATA, {
    variables: { id },
  });

  useEffect(() => {
    if (!dayDataQuery.loading && dayDataQuery.data) {
      const loadedData: PoolDayData[] = [
        ...dayDataQuery.data.poolDayDatas,
      ].reverse();

      const labels = loadedData.map((entry) =>
        new Date(entry.date * 1000).toLocaleDateString()
      );

      setChartLabels(labels);
      setLoadedData(loadedData);
    }
  }, [dayDataQuery.loading, dayDataQuery.data]);

  return { chartLabels, loadedData, loading: dayDataQuery.loading };
};

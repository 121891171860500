import { Button, Col, Row, Spin } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { poolsState, setPools } from "../../redux/reducers";
import PoolCards from "../PoolCards";
import { useQuery } from "@apollo/client";
import { LOAD_FEATURED_POOLS } from "../../graphql";
import { fetchPoolAprs } from "../../api/ApiCalls";

interface propType {
  poolAprs?: any;
}

const FeaturedPools = (props: propType) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  const pools = useSelector(poolsState);
  const dispatch = useDispatch();

  const handleViewPoolButton = () => {
    navigate("/pools");
  };

  const { error, loading, data } = useQuery(LOAD_FEATURED_POOLS);
  const [poolAprs, setPoolAprs] = useState({});
  useEffect(() => {
    if (data) {
      dispatch(setPools(data.pools));
    }
  }, [data]);

  useEffect(() => {
    const loadPoolAprs = async () => {
      // get token addresses of all pools
      const poolAddresses = data?.pools?.map((pool: any) => pool.id);

      if (!poolAddresses || poolAddresses.length === 0) {
        return;
      }

      // fetch aprs for all pools and store them in aprsByAddress object
      const poolaprs = await fetchPoolAprs(poolAddresses);
      const aprsByAddress = poolaprs.reduce((acc: any, obj: any) => {
        acc[obj.address] = obj.apr;
        return acc;
      }, {});

      setPoolAprs(aprsByAddress);
    };

    if (pools) {
      loadPoolAprs();
    }
  }, [data]);

  return (
    <Row
      style={{
        width: "100%",
        padding: md ? "36px" : sm ? "24px" : "16px",
        borderRadius: "12px",
        backgroundColor: "#252527",
      }}
    >
      <Row
        style={{
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Text size={"lg"} style={{ fontSize: md ? "32px" : "28px" }}>
          Featured Pools
        </Text>
        {sm && (
          <Button type="primary" onClick={handleViewPoolButton}>
            View all pools
          </Button>
        )}
      </Row>

      {pools.length > 0 ? (
        md ? (
          <Row
            gutter={[16, 16]}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            {pools.slice(0, 3).map((pool, index) => (
              <Col key={index} span={lg ? 8 : md ? 12 : 24}>
                <PoolCards Pool={pool} index={index} poolAprs={poolAprs} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row style={{ width: "100%", marginBottom: "20px", gap: "16px" }}>
            {pools.slice(0, 3).map((pool, index) => (
              <Col key={index} span={24}>
                <PoolCards Pool={pool} index={index} poolAprs={poolAprs} />
              </Col>
            ))}
          </Row>
        )
      ) : (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Spin size="large" />
        </Col>
      )}

      {xs && (
        <Button
          type="primary"
          onClick={handleViewPoolButton}
          style={{ width: "100%" }}
        >
          View all pools
        </Button>
      )}
    </Row>
  );
};

export default FeaturedPools;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

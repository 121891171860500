import { waitForTransaction, fetchBalance } from "@wagmi/core";
import { callContractMethod, callContractReadMethod } from "./walletUtils";
import PostionManagerAbi from "../abis/positionManager.json";
import WSHIDO_ABI from "../abis/wshido.json";
import {
  POSITION_MANAGER_ADDRESS,
  FACTORY_ADDRESS,
  SWAP_ROUTER_V2,
  MAX_FEE_PER_GAS,
  MAX_PRIORITY_FEE_PER_GAS,
  WSHIDO_ADDRESS,
} from "../constants";
import UniswapV3Factory from "@uniswap/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json";
import { erc20ABI } from "wagmi";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import UniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";
import { Percent, Token, CurrencyAmount, Price } from "@uniswap/sdk-core";
import { fromReadableAmount, toReadableAmount } from "./tradeUtils";
import {
  AddLiquidityOptions,
  Pool,
  MintOptions,
  Position,
  nearestUsableTick,
  RemoveLiquidityOptions,
  NonfungiblePositionManager,
  TickMath,
  TICK_SPACINGS,
  FeeAmount,
  encodeSqrtRatioX96,
  priceToClosestTick,
  computePoolAddress,
} from "@uniswap/v3-sdk";
import { NativeCurrency } from "./nativeCurrency";

const { ethers } = require("ethers"); // Import Ethers.js

const JSBI = require("jsbi");

export const getPoolAddress = async (
  walletAddress: any,
  token0Address: any,
  token1Address: any,
  fee: any
) => {
  const address = FACTORY_ADDRESS;
  const abi: any[] = JSON.parse(JSON.stringify(UniswapV3Factory.abi));
  const functionName = "getPool";
  //  sort token addreses before selecting token 0 and one
  const sortedTokens = [token0Address, token1Address].sort();

  const args: any[] = [sortedTokens[0], sortedTokens[1], fee];
  const fromAddress = walletAddress;

  const result = await callContractReadMethod({
    address,
    abi,
    functionName,
    args,
    fromAddress,
  });

  return result;
};

function wait(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const priceToTick = async (
  price: number,
  decimalsToken0: number,
  decimalsToken1: number,
  tickSpacing: number,
  priceType: string
): Promise<number> => {
  // 1. Convert price to sqrtPrice
  const priceFormatted = price * Math.pow(10, decimalsToken0);
  const token1ReserveRatio = Math.pow(10, decimalsToken1);
  const token0ReserveRatio = priceFormatted;

  // 2. Calculate sqrtPriceRatio
  const sqrtPriceRatio = token1ReserveRatio / token0ReserveRatio;

  // 3. Calculate tickForPrice
  const tickForPrice = Math.floor(Math.log(sqrtPriceRatio) / Math.log(1.0001));

  // 4. Adjust the tick to the tick spacing of the pool
  const closestLowTick = Math.floor(tickForPrice / tickSpacing) * tickSpacing;

  if (priceType === "High price") {
    return closestLowTick + tickSpacing;
  }

  return closestLowTick;
};

export const createAndMintPool = async (
  walletAddress: any,
  fee: any,
  token0Data: any,
  token1Data: any,
  sqrtPriceX96: any,
  web3: Web3,
  chainId: number,
  lowPriceRange: any,
  highPriceRange: any
) => {
  try {
    const token0Address = token0Data.token0Address;
    const token0DepositAmount = token0Data.token0Amount;
    const token0Decimals = token0Data.token0Decimals;

    const token1Address = token1Data.token1Address;
    const token1DepositAmount = token1Data.token1Amount;
    const token1Decimals = token1Data.token1Decimals;

    const [token0Symbol, token1Symbol] = await Promise.all([
      getTokenSymbol(walletAddress, token0Address),
      getTokenSymbol(walletAddress, token1Address),
    ]);

    const tokenA = new Token(
      Number(chainId),
      token0Address,
      Number(token0Decimals),
      token0Symbol,
      token0Symbol
    );
    const tokenB = new Token(
      Number(chainId),
      token1Address,
      Number(token1Decimals),
      token1Symbol,
      token1Symbol
    );

    const currentTick = TickMath.getTickAtSqrtRatio(sqrtPriceX96);

    let tickLower;
    let tickUpper;

    if (!lowPriceRange) {
      tickLower = nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[fee as FeeAmount]
      );
    } else {
      tickLower = tryParseTick(
        tokenA,
        tokenB,
        fee as FeeAmount,
        String(lowPriceRange)
      );
    }

    if (!highPriceRange) {
      tickUpper = nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[fee as FeeAmount]
      );
    } else {
      tickUpper = tryParseTick(
        tokenA,
        tokenB,
        fee as FeeAmount,
        String(highPriceRange)
      );
    }

    if (tickLower && tickUpper) {
      const configuredPool = new Pool(
        tokenA,
        tokenB,
        fee,
        sqrtPriceX96,
        new JSBI(0),
        currentTick
      );

      // Create position with calculated ticks
      const position = Position.fromAmounts({
        pool: configuredPool,
        tickLower: Number(tickLower),
        tickUpper: Number(tickUpper),
        amount0: token0DepositAmount,
        amount1: token1DepositAmount,
        useFullPrecision: true,
      });

      const mintOptions: MintOptions = {
        recipient: walletAddress,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20,
        slippageTolerance: new Percent(50, 10_000),
        createPool: true,
      };

      const { calldata: mintCalldata, value: mintValue } =
        NonfungiblePositionManager.addCallParameters(position, mintOptions);

      const transaction: any = {
        data: mintCalldata,
        to: POSITION_MANAGER_ADDRESS,
        value: mintValue,
        from: walletAddress,
      };

      const estimateGas = await web3.eth.estimateGas(transaction);

      transaction["gas"] = estimateGas.toString();

      const tx = await web3.eth.sendTransaction(transaction);

      return tx;
    } else {
      throw new Error("Invalid price range");
    }
  } catch (error) {
    console.error("Error in createAndMintPool:", error);
    throw error;
  }
};

export const createPosition = async (
  walletAddress: any,
  fee: any,
  token0Address: any,
  token1Address: any,
  web3: Web3,
  token0DepositAmount: BigNumber,
  token1DepositAmount: BigNumber,
  lowPriceRange: any,
  highPriceRange: any
) => {
  try {
    const poolAddress: any = await getPoolAddress(
      walletAddress,
      token0Address,
      token1Address,
      fee
    );

    if (poolAddress !== "0x0000000000000000000000000000000000000000") {
      const poolContract = new web3.eth.Contract(
        UniswapV3Pool.abi,
        poolAddress
      );
      const poolData = await getPoolData(poolContract);
      const chain_id = Number(await web3.eth.getChainId());

      // get token 0 and token 1 decimals here
      const [token0Decimals, token0Symbol, token1Decimals, token1Symbol] =
        await Promise.all([
          getTokenDecimals(walletAddress, token0Address),
          getTokenSymbol(walletAddress, token0Address),
          getTokenDecimals(walletAddress, token1Address),
          getTokenSymbol(walletAddress, token1Address),
        ]);

      const token0 = new Token(
        Number(chain_id),
        poolData.token0,
        Number(token0Decimals),
        token0Symbol,
        token0Symbol
      );
      const token1 = new Token(
        Number(chain_id),
        poolData.token1,
        Number(token1Decimals),
        token1Symbol,
        token1Symbol
      );

      const configuredPool = new Pool(
        token0,
        token1,
        Number(poolData.fee),
        poolData.sqrtPriceX96,
        poolData.liquidity,
        Number(poolData.tick.toString())
      );

      let tickLower;
      let tickUpper;

      if (!lowPriceRange) {
        tickLower = nearestUsableTick(
          TickMath.MIN_TICK,
          TICK_SPACINGS[fee as FeeAmount]
        );
      } else {
        tickLower = tryParseTick(
          token0,
          token1,
          fee as FeeAmount,
          String(lowPriceRange)
        );
      }

      if (!highPriceRange) {
        tickUpper = nearestUsableTick(
          TickMath.MAX_TICK,
          TICK_SPACINGS[fee as FeeAmount]
        );
      } else {
        tickUpper = tryParseTick(
          token0,
          token1,
          fee as FeeAmount,
          String(highPriceRange)
        );
      }

      if (tickLower && tickUpper) {
        console.log({
          pool: configuredPool,
          tickLower: tickLower,
          tickUpper: tickUpper,
          amount0: token0DepositAmount.toString(),
          amount1: token1DepositAmount.toString(),
          useFullPrecision: true,
        });
        const position = Position.fromAmounts({
          pool: configuredPool,
          tickLower: tickLower,
          tickUpper: tickUpper,
          amount0: token0DepositAmount.toString(),
          amount1: token1DepositAmount.toString(),
          useFullPrecision: true,
        });

        const mintOptions: MintOptions = {
          recipient: walletAddress,
          deadline: Math.floor(Date.now() / 1000) + 60 * 20,
          slippageTolerance: new Percent(50, 10_000),
        };

        // get calldata for minting a position
        const { calldata, value } =
          NonfungiblePositionManager.addCallParameters(position, mintOptions);

        const transaction: any = {
          data: calldata,
          to: POSITION_MANAGER_ADDRESS,
          value: value,
          from: walletAddress,
        };

        const estimateGas = await web3.eth.estimateGas(transaction);

        transaction["gas"] = estimateGas.toString();

        const tx = await web3.eth.sendTransaction(transaction);

        return tx;
      } else {
        throw new Error("Invalid price range");
      }
    }
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const getTokenDecimals = async (
  walletAddress: any,
  tokenAddress: string
) => {
  try {
    const abi: any[] = JSON.parse(JSON.stringify(erc20ABI));
    const functionName = "decimals";
    const args: any[] = [];
    const fromAddress = walletAddress;

    const result = await callContractReadMethod({
      address: tokenAddress,
      abi,
      functionName,
      args,
      fromAddress,
    });

    return result;
  } catch (error: any) {
    return error;
  }
};

export const getTokenSymbol = async (
  walletAddress: any,
  tokenAddress: string
) => {
  try {
    const abi: any[] = JSON.parse(JSON.stringify(erc20ABI));
    const functionName = "symbol";
    const args: any[] = [];
    const fromAddress = walletAddress;

    const result = await callContractReadMethod({
      address: tokenAddress,
      abi,
      functionName,
      args,
      fromAddress,
    });

    return result;
  } catch (error: any) {
    return error;
  }
};

export const getTokenBalance = async (
  walletAddress: any,
  tokenAddress: string
) => {
  try {
    if (tokenAddress === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      try {
        const balance = await fetchBalance({
          address: walletAddress,
        });

        return Number(balance.formatted).toString();
      } catch (error) {
        console.error(`Error: ${error}`);
        return "0";
      }
    } else {
      const abi: any[] = JSON.parse(JSON.stringify(erc20ABI));
      const functionName = "balanceOf";
      const args: any[] = [walletAddress];
      const fromAddress = walletAddress;

      const result: any = await callContractReadMethod({
        address: tokenAddress,
        abi,
        functionName,
        args,
        fromAddress,
      });

      // get token decimals
      const decimals = await getTokenDecimals(walletAddress, tokenAddress);

      return Number(
        BigNumber(result)
          .dividedBy(10 ** decimals)
          .toString()
      );
    }
  } catch (error: any) {
    console.log(error);
    return "0";
  }
};

export const getPositionManagerAllowance = async (
  walletAddress: any,
  tokenAddress: string
) => {
  try {
    const abi: any[] = JSON.parse(JSON.stringify(erc20ABI));
    const functionName = "allowance";
    const args: any[] = [walletAddress, POSITION_MANAGER_ADDRESS];
    const fromAddress = walletAddress;

    const result: any = await callContractReadMethod({
      address: tokenAddress,
      abi,
      functionName,
      args,
      fromAddress,
    });

    // get token decimals
    const decimals = await getTokenDecimals(walletAddress, tokenAddress);

    return BigNumber(result).dividedBy(10 ** decimals);
  } catch (error: any) {
    return BigNumber(0);
  }
};

export const getPositionManagerApproval = async (
  walletAddress: any,
  tokenAddress: string,
  amount: any
) => {
  try {
    const contractABI: any[] = JSON.parse(JSON.stringify(PostionManagerAbi));
    const functionName = "approve";

    const args: any[] = [POSITION_MANAGER_ADDRESS, amount.toFixed()];
    const fromAddress = walletAddress;

    const result = await callContractMethod({
      contractAddress: tokenAddress,
      contractABI,
      functionName,
      args,
      fromAddress,
    });

    const data = await waitForTransaction({
      hash: result.hash,
    });

    return data;
  } catch (error: any) {
    throw error;
  }
};

export const getPoolData = async (poolContract: any) => {
  const [token0, token1, tickSpacing, fee, liquidity, slot0] =
    await Promise.all([
      poolContract.methods.token0().call(),
      poolContract.methods.token1().call(),
      poolContract.methods.tickSpacing().call(),
      poolContract.methods.fee().call(),
      poolContract.methods.liquidity().call(),
      poolContract.methods.slot0().call(),
    ]);

  return {
    token0: token0,
    token1: token1,
    tickSpacing: tickSpacing.toString(),
    fee: fee,
    liquidity: liquidity.toString(),
    sqrtPriceX96: slot0[0].toString(),
    tick: slot0[1].toString(),
  };
};

const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96));
const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2));

const expDecs = (decs: any) => {
  return JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decs));
};

export const calculaateSqrtPrice = async (
  tokenDecimals: any,
  sqrtRatioX96: any
) => {
  const [token0Decimals, token1Decimals] = tokenDecimals;
  const scalarNumerator = expDecs(token0Decimals);
  const scalarDenominator = expDecs(token1Decimals);

  const sqrtRatioX96BI = JSBI.BigInt(String(sqrtRatioX96));

  const inputNumerator = JSBI.multiply(sqrtRatioX96BI, sqrtRatioX96BI);
  const inputDenominator = Q192;

  const adjustedForDecimalsNumerator = JSBI.BigInt(
    JSBI.multiply(scalarDenominator, inputDenominator)
  );
  const adjustedForDecimalsDenominator = JSBI.BigInt(
    JSBI.multiply(scalarNumerator, inputNumerator)
  );

  const numerator = adjustedForDecimalsNumerator;
  const denominator = adjustedForDecimalsDenominator;

  const fraction = [numerator, denominator];

  return fraction;
};

export const getPrice = async (PoolInfo: any) => {
  let sqrtPriceX96 = PoolInfo.sqrtPriceX96;
  let Decimal0 = PoolInfo.Decimal1;
  let Decimal1 = PoolInfo.Decimal0;

  const amount: any = (10 ** Decimal1 / 10 ** Decimal0).toFixed(Decimal0);
  const buyOneOfToken0 = (sqrtPriceX96 / 2 ** 96) ** 2 / amount;
  const buyOneOfToken1: any = (1 / buyOneOfToken0).toFixed(Decimal0);

  return {
    token1: Number(buyOneOfToken1.toString()),
    token0: Number(buyOneOfToken0.toString()),
  };
};

export const toCustomFixed = (num: any, fixed: any) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num?.toString().match(re)[0];
};

export const decimalFormatWithoutRoundOffCrypto = (value: number) => {
  if (value === 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return value?.toFixed();
  }
};

export function toBNWithDecimals(amount: number, decimals: number) {
  const valueInUnits = ethers.utils.parseUnits(amount.toString(), decimals);
  return valueInUnits;
}

export const getSwapRouterAllowance = async (
  walletAddress: any,
  token: Token
) => {
  try {
    const abi: any[] = JSON.parse(JSON.stringify(erc20ABI));
    const functionName = "allowance";
    const args: any[] = [walletAddress, SWAP_ROUTER_V2];
    const fromAddress = walletAddress;

    const result: any = await callContractReadMethod({
      address: token.address,
      abi,
      functionName,
      args,
      fromAddress,
    });

    return BigNumber(result);
  } catch (error: any) {
    return error;
  }
};

export const constructPosition = async (
  token0Amount: CurrencyAmount<Token>,
  token1Amount: CurrencyAmount<Token>,
  poolContract: any
): Promise<any> => {
  // get pool info
  const poolInfo = await getPoolData(poolContract);

  // construct pool instance
  const USDC_DAI_POOL = new Pool(
    token0Amount.currency,
    token1Amount.currency,
    Number(poolInfo.fee),
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    Number(poolInfo.tick)
  );

  // create position using the maximum liquidity from input amounts
  return Position.fromAmounts({
    pool: USDC_DAI_POOL,
    tickLower:
      nearestUsableTick(Number(poolInfo.tick), Number(poolInfo.tickSpacing)) -
      poolInfo.tickSpacing * 2,
    tickUpper:
      nearestUsableTick(Number(poolInfo.tick), Number(poolInfo.tickSpacing)) +
      poolInfo.tickSpacing * 2,
    amount0: token0Amount.quotient,
    amount1: token1Amount.quotient,
    useFullPrecision: true,
  });
};

export async function addLiquidity(
  position: any,
  address: any,
  provider: any,
  poolContract: any,
  positionId: any,
  amount0Deposit: any,
  amount1Deposit: any
): Promise<any> {
  try {
    if (!address || !provider) {
      return;
    }
    const chain_id = Number(await provider.eth.getChainId());

    const token0 = new Token(
      Number(chain_id),
      position.token0,
      Number(position.token0Decimals),
      position.token0Symbol,
      position.token0Symbol
    );

    const token1 = new Token(
      Number(chain_id),
      position.token1,
      Number(position.token1Decimals),
      position.token1Symbol,
      position.token1Symbol
    );

    const currentPosition = await constructPosition(
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount(BigNumber(amount0Deposit), position.token0Decimals)
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount(BigNumber(amount1Deposit), position.token1Decimals)
      ),
      poolContract
    );

    const addLiquidityOptions: AddLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10_000),
      tokenId: JSBI.BigInt(positionId),
      createPool: true,
    };

    // get calldata for minting a position
    const { calldata, value } = NonfungiblePositionManager.addCallParameters(
      currentPosition,
      addLiquidityOptions
    );

    // build transaction
    const transaction: any = {
      data: calldata,
      to: POSITION_MANAGER_ADDRESS,
      value: value,
      from: address,
    };

    const estimateGas = await provider.eth.estimateGas(transaction);

    transaction["gas"] = estimateGas.toString();

    const tx = await provider.eth.sendTransaction(transaction);

    return tx;
  } catch (error: any) {
    throw error;
  }
}

export async function removeLiquidity(
  position: any,
  address: any,
  provider: any,
  percentage: any,
  poolContract: any,
  positionId: any
): Promise<any> {
  try {
    if (!address || !provider) {
      return;
    }

    const chain_id = Number(await provider.eth.getChainId());

    const token0 = new Token(
      Number(chain_id),
      position.token0,
      Number(position.token0Decimals),
      position.token0Symbol,
      position.token0Symbol
    );

    const token1 = new Token(
      Number(chain_id),
      position.token1,
      Number(position.token1Decimals),
      position.token1Symbol,
      position.token1Symbol
    );

    const currentPosition = await constructPosition(
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount(
          BigNumber(position.pooledToken0),
          Number(position.token0Decimals)
        )
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount(
          BigNumber(position.pooledToken1),
          Number(position.token1Decimals)
        )
      ),
      poolContract
    );

    const collectOptions: Omit<any, "tokenId"> = {
      expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(token0, 0),
      expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(token1, 0),
      recipient: address,
    };

    const removeLiquidityOptions: RemoveLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10_000),
      tokenId: BigNumber(positionId).toString(),
      // percentage of liquidity to remove
      liquidityPercentage: new Percent(percentage, 100),
      collectOptions,
    };

    // get calldata for minting a position
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(
      currentPosition,
      removeLiquidityOptions
    );

    // build transaction
    const transaction: any = {
      data: calldata,
      to: POSITION_MANAGER_ADDRESS,
      value: value,
      from: address,
    };

    const estimateGas = await provider.eth.estimateGas(transaction);

    transaction["gas"] = estimateGas.toString();

    const tx = await provider.eth.sendTransaction(transaction);

    return tx;
  } catch (err: any) {
    throw err;
  }
}

export async function collectFees(
  positionId: number,
  address: any,
  provider: any,
  position: any
): Promise<any> {
  try {
    if (!address || !provider) {
      return;
    }

    const chain_id = Number(await provider.eth.getChainId());

    const token0 = new Token(
      Number(chain_id),
      position.token0,
      Number(position.token0Decimals),
      position.token0Symbol,
      position.token0Symbol
    );
    const token1 = new Token(
      Number(chain_id),
      position.token1,
      Number(position.token1Decimals),
      position.token1Symbol,
      position.token1Symbol
    );

    const collectOptions: any = {
      tokenId: BigNumber(positionId),
      expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount(
          BigNumber(position.tokensOwed0),
          position.token0Decimals
        )
      ),
      expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount(
          BigNumber(position.tokensOwed1),
          position.token1Decimals
        )
      ),
      recipient: address,
    };

    const { calldata, value } =
      NonfungiblePositionManager.collectCallParameters(collectOptions);

    // build transaction
    const transaction: any = {
      data: calldata,
      to: POSITION_MANAGER_ADDRESS,
      value: value,
      from: address,
    };

    const estimateGas = await provider.eth.estimateGas(transaction);

    transaction["gas"] = estimateGas.toString();

    const tx = await provider.eth.sendTransaction(transaction);

    return tx;
  } catch (error) {
    console.log(error, "---------------error");
    throw error;
  }
}

function getTickAtSqrtRatio(sqrtPriceX96: any) {
  let tick = Math.floor(Math.log((sqrtPriceX96 / Q96) ** 2) / Math.log(1.0001));
  return tick;
}

export async function getTokenAmounts(
  liquidity: any,
  sqrtPriceX96: any,
  tickLow: any,
  tickHigh: any,
  token0Decimal: any,
  token1Decimal: any
) {
  let sqrtRatioA: any = Math.sqrt(1.0001 ** tickLow).toFixed(token0Decimal);
  let sqrtRatioB: any = Math.sqrt(1.0001 ** tickHigh).toFixed(token1Decimal);

  let currentTick = getTickAtSqrtRatio(sqrtPriceX96);

  let currentRatio: any = Math.sqrt(1.0001 ** currentTick).toFixed(
    token0Decimal
  );
  let amount0wei = 0;
  let amount1wei = 0;

  if (currentTick <= tickLow) {
    amount0wei = Math.floor(
      liquidity * ((sqrtRatioB - sqrtRatioA) / (sqrtRatioA * sqrtRatioB))
    );
  }
  if (currentTick > tickHigh) {
    amount1wei = Math.floor(liquidity * (sqrtRatioB - sqrtRatioA));
  }
  if (currentTick >= tickLow && currentTick < tickHigh) {
    amount0wei = Math.floor(
      liquidity * ((sqrtRatioB - currentRatio) / (currentRatio * sqrtRatioB))
    );
    amount1wei = Math.floor(liquidity * (currentRatio - sqrtRatioA));
  }

  let amount0Human = amount0wei / 10 ** token0Decimal;
  let amount1Human = amount1wei / 10 ** token1Decimal;

  return [amount0Human, amount1Human];
}

export function formatLiquidityAmount(liquidity: any, decimals = 18) {
  try {
    return liquidity / 10 ** Number(decimals);
  } catch (error) {
    return 0;
  }
}

export const wrapWShido = async (walletAddress: any, amount: string) => {
  try {
    const abi: any = JSON.parse(JSON.stringify(WSHIDO_ABI));
    const functionName = "deposit";
    const fromAddress = walletAddress;
    const value = ethers.utils.parseUnits(amount.toString(), 18);

    const result: any = await callContractMethod({
      fromAddress,
      contractAddress: WSHIDO_ADDRESS,
      contractABI: abi,
      functionName,
      value: value,
    });

    return result;
  } catch (error: any) {
    throw error;
  }
};

export const unwrapWShido = async (walletAddress: any, amount: string) => {
  try {
    const abi: any = JSON.parse(JSON.stringify(WSHIDO_ABI));
    const functionName = "withdraw";
    const fromAddress = walletAddress;
    const args: any[] = [ethers.utils.parseUnits(amount.toString(), 18)];

    const result: any = await callContractMethod({
      fromAddress,
      contractAddress: WSHIDO_ADDRESS,
      contractABI: abi,
      functionName,
      args,
    });

    return result;
  } catch (error: any) {
    throw error;
  }
};

export function tryParsePrice(
  baseToken?: Token,
  quoteToken?: Token,
  value?: string
) {
  if (!baseToken || !quoteToken || !value) {
    return undefined;
  }

  if (!value.match(/^\d*\.?\d+$/)) {
    return undefined;
  }

  const [whole, fraction] = value.split(".");

  const decimals = fraction?.length ?? 0;
  const withoutDecimals = JSBI.BigInt((whole ?? "") + (fraction ?? ""));

  return new Price(
    baseToken,
    quoteToken,
    JSBI.multiply(
      JSBI.BigInt(10 ** decimals),
      JSBI.BigInt(10 ** baseToken.decimals)
    ),
    JSBI.multiply(withoutDecimals, JSBI.BigInt(10 ** quoteToken.decimals))
  );
}

export function tryParseTick(
  baseToken?: Token,
  quoteToken?: Token,
  feeAmount?: FeeAmount,
  value?: string
): number | undefined {
  if (!baseToken || !quoteToken || !feeAmount || !value) {
    return undefined;
  }

  const price = tryParsePrice(baseToken, quoteToken, value);

  if (!price) {
    return undefined;
  }

  let tick: number;

  // check price is within min/max bounds, if outside return min/max
  const sqrtRatioX96 = encodeSqrtRatioX96(price.numerator, price.denominator);

  if (JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)) {
    tick = TickMath.MAX_TICK;
  } else if (JSBI.lessThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO)) {
    tick = TickMath.MIN_TICK;
  } else {
    // this function is agnostic to the base, will always return the correct tick
    tick = priceToClosestTick(price);
  }

  return nearestUsableTick(tick, TICK_SPACINGS[feeAmount]);
}

import React from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Text from "../Text";

const FAQHeader = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: sm ? "24px" : "36px",
        background: "linear-gradient(267deg, #4CA8F8 14.16%, #2B66F6 76.6%)",
        borderRadius: "12px",
        width: "100%",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Text
        size="xxl"
        style={{ fontWeight: "700", fontSize: "36px", textAlign: "center" }}
      >
        Frequently Asked Questions
      </Text>
      <Text size="sm" style={{ fontWeight: "400", textAlign: "center" }}>
        Get quick answers to help you make the most of Shido’s network, DEX, and
        features.
      </Text>
    </div>
  );
};

export default FAQHeader;

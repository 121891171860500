import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

import { usePoolDayData } from "../hooks/usePoolDayData";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const VolumeChart = ({ pool }: any) => {
  const { chartLabels, loadedData, loading } = usePoolDayData(pool.id);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: `Volume`,
        data: loadedData.map((entry: any) => entry.volumeUSD),
        backgroundColor: "rgb(59, 135, 247)",
        borderColor: "rgb(59, 135, 247)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Date",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Volume ($)",
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default VolumeChart;
